import React, {useState} from "react";
import {EditorState, Modifier} from "draft-js";
import {Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {BsCloudUpload} from "react-icons/bs";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Uploads from "../../Files";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";

const Transition = React.forwardRef(function Transition(props, ref){
  return <Slide direction="up" ref={ref} {...props} />;
});

class CustomOption extends React.Component {
  state = {
    dialogEdit: false
  };

  static propTypes = {
    onChange: PropTypes.func,
    editorState: PropTypes.object
  };

  addFile = async file => {
    const {editorState, onChange} = this.props;

    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      `[file-link=${file.link}]`,
      editorState.getCurrentInlineStyle()
    );

    onChange(
      EditorState.push(
        editorState,
        Modifier.splitBlock(contentState, editorState.getSelection()),
        "insert-characters"
      )
    );
  };

  openDialog = () => {
    this.setState({dialogEdit: true});
  };

  closeDialog = () => {
    this.setState({dialogEdit: false});
  };

  selectedFile = async file => {
    await this.addFile(file);
    this.closeDialog();
  };

  render() {
    return (
      <React.Fragment>
        <Dialog
          open={this.state.dialogEdit}
          onClose={this.closeDialog}
          fullScreen
          TransitionComponent={Transition}
        >
          <DialogTitle>Uploads de arquivos</DialogTitle>
          <DialogContent dividers>
            <Grid item xs={12}>
              <Uploads isDialog={true} selectedFile={this.selectedFile} />
            </Grid>
            <Box display="flex" justifyContent="flex-end" mb={4}>
              <Button onClick={this.closeDialog}>Sair</Button>
            </Box>
          </DialogContent>
        </Dialog>
        <Tooltip title="Arquivos">
          <IconButton aria-label="delete" onClick={this.openDialog}>
            <BsCloudUpload size="15px" />
          </IconButton>
        </Tooltip>
      </React.Fragment>
    );
  }
}

const WysiswygEditorExample = ({config, editorState, setEditorState}) => {
  return (
    <React.Fragment>
      <Editor
        editorStyle={{
          width: "100%",
          minHeight: 150,
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: "lightgray"
        }}
        toolbar={config}
        editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={editorState => setEditorState(editorState)}
        toolbarCustomButtons={[ <CustomOption /> ]}
      />
      {/* <textarea
        style={{ width: '100%', height: 200 }}
        disabled
        value={JSON.stringify(convertToRaw(editorState.getCurrentContent()))}
     /> */}
    </React.Fragment>
  );
};

export default WysiswygEditorExample;
