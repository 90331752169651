import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/CopyAllOutlined';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { NotificationManager } from "react-notifications";

import axios from "services/auth/jwt/config";

export default function FormDialog({ setOpen, open, data, label }) {
    const [showToken, setShowToken] = React.useState(false);
    const [disabled, setDisabled] = React.useState(data ? true : false);

    const [name, setName] = React.useState(data ? data.nome : "");
    const [descricao, setDescricao] = React.useState(data ? data.body : "");
    const [token, setToken] = React.useState(data ? data.token : "");
    const [webhook, setWebhook] = React.useState(data ? data.webhookUrl : "");

    const tokenAuth = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + tokenAuth;

    const handleClose = () => {
        setOpen(false);
    };

    const handleCopy = async (e) => {
        await navigator.clipboard.writeText(e.target.value);
        NotificationManager.success("Url copiado!")
    }

    const handleClickShowToken = () => setShowToken((show) => !show);

    const handleSave = () => {
        const dataNew = {
            nome: name,
            descricao: descricao,
            token: token,
            label
        }

        axios.post("/integracao", dataNew)
            .then(success => NotificationManager.success("Integração criada!"))
            .catch(err => NotificationManager.error("Erro ao criar integração"))

        handleClose();
    }

    const handleEdit = () => {


        const dataEdit = {
            _id: data.id,
            nome: name,
            descricao: descricao,
            token: token,
            label
        }

        axios.put("/integracao", dataEdit)
            .then(success => NotificationManager.success("Integração atualizada!"))
            .catch(err => NotificationManager.error("Erro ao atualizar integração"))

        handleClose();
    }

    return (
        <React.Fragment>
            <Dialog
                maxWidth="sm"
                fullWidth
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>IUGU</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Gere o token em sua conta da Iugu e salve-o aqui.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        required
                        disabled={disabled}
                        margin="dense"
                        id="name"
                        name="name"
                        label="Nome"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <TextField
                        required
                        disabled={disabled}
                        margin="dense"
                        id="descricao"
                        name="descricao"
                        label="Descrição"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={descricao}
                        onChange={(e) => setDescricao(e.target.value)}
                    />
                    <TextField
                        required
                        disabled={disabled}
                        margin="dense"
                        id="token"
                        name="token"
                        label="token"
                        type={showToken ? "text" : "password"}
                        fullWidth
                        variant="standard"
                        value={token}
                        onChange={(e) => setToken(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <React.Fragment>
                                    <IconButton
                                        style={{ marginLeft: '5px' }}
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowToken}
                                        edge="end"
                                    >
                                        {showToken ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </React.Fragment>
                            ),
                        }}
                    />

                    {data &&
                        <>
                            <DialogContentText style={{ marginTop: '20px' }}>
                                <code>Webhook</code> para receber estimulos via webhook adicione essa url em sua conta Iugu.
                            </DialogContentText>
                            <TextField
                                disabled
                                margin="dense"
                                id="webhook"
                                name="webhook"
                                label="Url webhook"
                                value={webhook}
                                type="text"
                                fullWidth
                                variant="standard"
                                InputProps={{
                                    endAdornment: (
                                        <React.Fragment>
                                            <IconButton
                                                style={{ marginLeft: '5px' }}
                                                aria-label="toggle password visibility"
                                                onClick={handleCopy}
                                                edge="end"
                                            >
                                                <ContentCopyIcon />
                                            </IconButton>
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        </>
                    }
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose} color='error' >Cancelar</Button>
                    {data
                        ? disabled
                            ? <Button type="button" onClick={() => setDisabled(false)}>Editar </Button>
                            : <Button type="button" onClick={handleEdit}>Salvar </Button>
                        : <Button type="button" onClick={handleSave}>Adicionar</Button>
                    }
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}