import React from "react";
import {Redirect, Route, Switch} from "react-router";
import {useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import Pages from "./Pages/index";
import Error404 from "./Pages/404";
import Login from "./Auth/Login";
import Register from "./Auth/Register";
import ForgotPasswordPage from "./Auth/ForgotPassword";

const RestrictedRoute = ({component: Component, ...rest}) => {
  const {authUser} = useSelector(({auth}) => auth);
  // console.log(rest);

  return (
    <Route
      {...rest}
      render={props =>
        authUser ? !authUser.plano &&
        rest.location.pathname != "/app/planos" ? (
          <Redirect
            to={{
              pathname: "/app/planos",
              state: {from: props.location}
            }}
          />
        ) : (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/signin",
              state: {from: props.location}
            }}
          />
        )}
    />
  );
};

const Routes = () => {
  const {authUser} = useSelector(({auth}) => auth);
  const location = useLocation();

  if (location.pathname === "" || location.pathname === "/") {
    return <Redirect to={"/app/dashboards"} />;
  } else if (
    authUser &&
    [ "/signin", "/signup", "/forgot-password" ].includes(location.pathname)
  ) {
    return <Redirect to={"/app/dashboards"} />;
  }

  return (
    <React.Fragment>
      <Switch>
        <Route path="/signin" component={Login} />
        <Route path="/signup" component={Register} />
        <Route path="/forgot-password" component={ForgotPasswordPage} />
        <RestrictedRoute path={"/app"} component={Pages} />
        <Route component={Error404} />
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
