import React, { useState, useEffect } from "react";
import CmtObjectSummary from "@coremat/CmtObjectSummary";
import CmtAvatar from "@coremat/CmtAvatar";
import UserInfo from "./UserInfo";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import { NotificationManager } from "react-notifications";
import clsx from "clsx";
import { useSelector } from "react-redux";
import axios from "services/auth/jwt/config";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Lottie from 'react-lottie';
import animationQrCode from '@lottie/QrCode/loading.json';
import animationWhatsapp from '@lottie/QrCode/whatsapp.json'
import Fade from '@mui/material/Fade';

// queries
import useFetchGetSessao from "@queries/whatsapp";


const MySwal = withReactContent(Swal);

const useStyles = makeStyles(theme => ({
  cardRoot: {
    position: "relative",
    "& .Cmt-header-root": {
      paddingBottom: 0
    }
  },
  cardServicesView: {
    position: "relative",
    display: "flex",
    justifyContent: "center"
  },
  userDetailRoot: {
    position: "relative",
    width: 112,
    height: 112,
    marginTop: '10%',
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: 25
  },
  cardTitleRoot: {
    marginBottom: 24,
    "& .Cmt-badge-avatar": {
      padding: 5,
      borderRadius: "50%"
    },
    "& .Cmt-badge": {
      padding: 0,
      backgroundColor: "transparent",
      marginBottom: -36,
      marginLeft: -15
    },
    "& .Cmt-user-info": {
      marginTop: 15,
      "& .title": {
        fontSize: 16,
        fontWeight: theme.typography.fontWeightBold
      }
    }
  },
  formControl: {
    margin: theme.spacing(2)
  },
  selectEmpty: {
    marginTop: theme.spacing(4)
  },
  cirProRoot: {
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: 1
  },
  cirProGrey: {
    color: theme.palette.grey[200]
  },
  avatarRoot: {
    border: `solid 2px ${theme.palette.common.white}`,
    marginTop: -125,
    marginBottom: 13
  }
}));

const UserDetail = ({ setor, setores }) => {
  const classes = useStyles();

  const [userDetails, setUseDetail] = useState({
    conected: false,
    badge: "/images/icons/badge-copy.png",
    name: "",
    device: "",
    job_title: "",
    stats: {
      Device: "",
      Dispositivo: "",
      'Versão': "",
      Status: "DESCONECTADO"
    }
  });
  const [conected, setConected] = useState(false);
  const [readQrCode, setReadQrCode] = useState(false);
  const [reiniciarSessao, setReiniciarSessao] = useState("static");
  const [valueReiniciarSessao, setValueReiniciarSessao] = useState(100);
  const [qrCode, setQrcode] = useState("");
  const [isSession, setIsSession] = useState(false);
  const [isExclusiveSector, setIsExclusiveSector] = useState(false);
  const [imagens, setImagens] = useState()
  const [imagemSelected, setImagemSelected] = useState()

  // animation
  const [animationData, setAnimationData] = useState(animationWhatsapp)
  const [fadeKey, setFadeKey] = useState(0);

  const { authUser } = useSelector(({ auth }) => auth);
  const { isLoading, isFetching, error, data, refetch } = useFetchGetSessao(setor._id)

  const defaultOptions = {
    loop: true,
    autoplay: true,
    speed: 1,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  useEffect(() => {
    axios.get("imagens?type=whatsapp")
      .then(success => {
        setImagens(success.data)
        success.data.length > 0 && setImagemSelected(success.data[0]._id)
      })
      .catch(err => console.log(err))
  }, [])

  const activReadQrCode = () => {
    setReadQrCode(true);
    setAnimationData(animationQrCode)
    setFadeKey(fadeKey + 1)
  }

  const desactivReadQrCode = () => {
    setReadQrCode(false);
    setAnimationData(animationWhatsapp)
    setFadeKey(fadeKey + 1)
  }

  useEffect(() => {
    if (data.statusChange == "delete") {
      setConected(false);
      // desactivReadQrCode();
      setQrcode();
      setUseDetail({
        conected: false,
        badge: "/images/icons/badge-copy.png",
        name: "",
        device: "",
        job_title: "",
        stats: {
          Device: "",
          Dispositivo: "",
          'Versão': "",
          Status: "DESCONECTADO"
        }
      });
      desactivReadQrCode();
    } else if (data.isConected) {
      var newUserDetails = { ...userDetails };
      newUserDetails.conected = data.isConected;
      newUserDetails.stats.Status = "ATIVO";
      newUserDetails.stats.Device = data.session.device;
      newUserDetails.name = data.host ? data.host.device.pushName : null;
      newUserDetails.stats.multidevice = String(
        data.multidevice
      ).toUpperCase();

      setIsExclusiveSector(data.isExclusiveSector);
      setConected(true);
      setUseDetail(newUserDetails);
      desactivReadQrCode();
    } else {
      if (data.qrCode) {
        setQrcode(data.qrCode);
      } else {
        setConected(false);
        // desactivReadQrCode();
        setQrcode(false);
        setUseDetail({
          conected: false,
          badge: "/images/icons/badge-copy.png",
          name: "",
          device: "",
          job_title: "",
          stats: {
            Device: "",
            Dispositivo: "",
            Status: "DESCONECTADO"
          }
        });
      }
    }
  }, [data])

  useEffect(() => {
    if (setor._id) {
      refetch() // habilita o react-query para ficar buscando por departamento
    }
  }, [setor, refetch]);

  const handleChangeQrCode = () => {
    activReadQrCode()
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios
      .post("whatsapp/init", {
        sessionId: setor._id,
        imagemId: imagemSelected
      })
      .then(succes => {
        if (succes.data.error) {
          desactivReadQrCode();
          setIsSession(true);
          NotificationManager.error(succes.data.message);
        } else {
          NotificationManager.success(succes.data.message);
        }
      })
      .catch(error => {
        desactivReadQrCode();
        NotificationManager.error(error.response.data.message);
      });
  };

  const handleChangeStopSessao = () => {
    setReiniciarSessao("indeterminate");
    setValueReiniciarSessao(80);

    axios
      .post("whatsapp/stop", {
        sessionId: setor._id
      })
      .then(succes => {
        setReiniciarSessao("static");
        setValueReiniciarSessao(100);
        NotificationManager.success(succes.data.message);
      })
      .catch(error => {
        setReiniciarSessao("static");
        setValueReiniciarSessao(100);
        NotificationManager.error("Falha ao pausar sessão!");
      });
  };

  const handleChangeExclusiveSector = () => {
    setIsExclusiveSector(!isExclusiveSector);
    axios
      .post("whatsapp/setExclusiveSector", {
        departamento: setor._id,
        isExclusiveSector: !isExclusiveSector
      })
      .then(succes => {
        NotificationManager.success(succes.data.message);
      })
      .catch(error => {
        NotificationManager.error("Falha ao reinciar sessão!");
      });
  };

  const handleChangeReiniciarSessao = () => {
    setReiniciarSessao("indeterminate");
    setValueReiniciarSessao(80);

    axios
      .post("whatsapp/reiniciar", {
        sessionId: setor._id
      })
      .then(succes => {
        setReiniciarSessao("static");
        setValueReiniciarSessao(100);
        NotificationManager.success(succes.data.message);
      })
      .catch(error => {
        setReiniciarSessao("static");
        setValueReiniciarSessao(100);
        NotificationManager.error("Falha ao reinciar sessão!");
      });
  };

  const handleChangeExcluirSessao = () => {
    setReiniciarSessao("indeterminate");
    setValueReiniciarSessao(80);
    setQrcode(false);
    axios
      .post("whatsapp/excluirSesao", {
        sessionId: setor._id
      })
      .then(succes => {
        var newUserDetails = { ...userDetails };

        newUserDetails.conected = false;
        newUserDetails.stats.Status = "DESCONECTADO";
        newUserDetails.name = "-";
        newUserDetails.job_title = "-";
        setUseDetail(newUserDetails);
        setConected(false);
        setReiniciarSessao("static");
        setValueReiniciarSessao(100);
        desactivReadQrCode();
        NotificationManager.success(succes.data.message);
      })
      .catch(error => {
        desactivReadQrCode();
        setReiniciarSessao("static");
        setValueReiniciarSessao(100);
        NotificationManager.error("Erro ao deletar sessão!");
      });
  };

  const handleImagem = (e) => {
    setImagemSelected(e.target.value)
  }

  const sincronizarMensagens = () => { };

  return (
    <React.Fragment>
      {!conected && !qrCode &&
        <Fade in key={fadeKey} timeout={1000}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="80%"
          >
            <Lottie
              options={defaultOptions}
              height={200}
              width={200}
            />
          </Box>
        </Fade>
      }
      {conected &&
        <React.Fragment>
          <Box className={classes.userDetailRoot}>
            <CircularProgress
              className={clsx(classes.cirProRoot, classes.cirProGrey)}
              variant="static"
              color="secondary"
              size={112}
              value={100}
              thickness={2}
            />
            <CircularProgress
              className={classes.cirProRoot}
              variant={reiniciarSessao}
              color="secondary"
              size={112}
              value={valueReiniciarSessao}
              thickness={1}
            />
          </Box>
          <Box className={classes.cardTitleRoot}>
            <CmtObjectSummary
              avatar={
                <CmtAvatar
                  className={classes.avatarRoot}
                  size={90}
                  src={authUser.photo}
                  alt={authUser.nome}
                />
              }
              title={userDetails.name}
              titleProps={{
                style: { fontSize: 16, fontWeight: "bold", marginBottom: 5 }
              }}
              subTitle={userDetails.job_title}
              align="vertical"
            />
          </Box>
        </React.Fragment>
      }
      {readQrCode && qrCode && (
        <Fade in key={fadeKey} timeout={1000}>
          <Box className={classes.cardTitleRoot}>
            <CmtObjectSummary
              avatar={
                <div>
                  <img src={qrCode} alt="qrCode" width="50%" />
                </div>
              }
              title="Qr Code"
              titleProps={{
                style: { fontSize: 16, fontWeight: "bold", marginBottom: 5 }
              }}
              align="vertical"
            />
          </Box>
        </Fade>
      )}
      <React.Fragment>
        <Box>
          {setor._id && (
            <React.Fragment>
              <UserInfo
                userDetails={userDetails}
                imagens={imagens}
                handleImagem={handleImagem}
                imagemSelected={imagemSelected}
                userFunctions={{
                  readQrCode,
                  isSession,
                  handleChangeQrCode,
                  handleChangeReiniciarSessao,
                  handleChangeExcluirSessao,
                  handleChangeStopSessao,
                  sincronizarMensagens,
                  isExclusiveSector,
                  handleChangeExclusiveSector
                }}
              />
            </React.Fragment>
          )}
        </Box>
      </React.Fragment>
    </React.Fragment>
  );
};

export default UserDetail;
