import React from "react"
import Iugu from "./Iugu"

const Sistemas = ({ itemSelected, onClose, open, label }) => {

    switch (label) {
        case 'iugu':
            return <Iugu open={open} data={itemSelected} setOpen={onClose} label={label} />
        default:
            return null
    }

}

export default Sistemas