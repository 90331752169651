import React, {useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import GridContainer from "@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import AppTextInput from "@jumbo/components/Common/formElements/AppTextInput";
import Button from "@material-ui/core/Button";
import {requiredMessage} from "@jumbo/constants/ErrorMessages";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {NotificationManager} from "react-notifications";
import axios from "services/auth/jwt/config";
import Loader from "react-loaders";
import NumberFormat from "react-number-format";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: "relative"
  },
  dialogTitleRoot: {
    "& .MuiTypography-h6": {
      fontSize: 18,
      color: theme.palette.common.dark
    }
  }
}));

const ORIGENS = [ "whatsapp" ];

function NumberFormatCustom(props){
  const {onChange, ...other} = props;

  return (
    <NumberFormat
      {...other}
      onValueChange={values => {
        onChange(values.formattedValue);
      }}
      format="## (##) #####-####"
    />
  );
}

const CreateLead = ({open, handleDialog, setUpdate, currentLead}) => {
  const classes = useStyles();

  const [ fname, setFname ] = useState(currentLead ? currentLead.nome : "");
  const [ email, setEmail ] = useState(currentLead ? currentLead.email : "");
  const [ telefone, setTelefone ] = useState(
    currentLead ? currentLead.telefone : ""
  );
  const [ origem, setOrigem ] = useState(currentLead ? currentLead.origem : "");

  const [ fnameError, setFnameError ] = useState("");
  const [ emailError, setEmailError ] = useState("");
  const [ telefoneError, setTelefoneError ] = useState("");
  const [ origemError, setOrigemError ] = useState("");

  const [ loader, setLoader ] = useState(false);

  const token = localStorage.getItem("token");
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  const checkValidations = () => {
    if (!fname) {
      setFnameError(requiredMessage);
    } else if (!telefone) {
      setTelefoneError(requiredMessage);
    } else if (!origem) {
      setOrigemError(requiredMessage);
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    setLoader(true);

    var chatId;

    if (origem == "whatsapp") {
      chatId = telefone.replace(/[^a-zA-Z0-9]/g, "").replace(/\s/g, "");
    }

    if (currentLead) {
      axios
        .post("leads/update", {
          _id: currentLead._id,
          nome: fname,
          email,
          telefone: telefone.replace(/[^a-zA-Z0-9]/g, "").replace(/\s/g, ""),
          chatId
        })
        .then(succes => {
          setUpdate(Math.random());
          handleDialog();
          setLoader(false);
          NotificationManager.success("Lead criado com sucesso!");
        })
        .catch(error => {
          setLoader(false);
          handleDialog();
          NotificationManager.error(error.response.data.message);
        });
    } else {
      const data = {
        nome: fname,
        email,
        telefone: telefone.replace(/[^a-zA-Z0-9]/g, "").replace(/\s/g, ""),
        origem: origem,
        chatId
      };

      axios
        .post("leads/novo", {
          lead: data
        })
        .then(succes => {
          setUpdate(Math.random());
          handleDialog();
          setLoader(false);
          NotificationManager.success("Lead criado com sucesso!");
        })
        .catch(error => {
          setLoader(false);
          handleDialog();
          NotificationManager.error(error.response.data.message);
        });
    }
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleDialog}
      className={classes.dialogRoot}
    >
      <DialogTitle className={classes.dialogTitleRoot}>
        {currentLead ? "Editar Lead" : "Criar Lead"}
      </DialogTitle>
      <DialogContent dividers>
        <Box
          display="flex"
          flexDirection={{xs: "column", md: "row"}}
          alignItems="center"
          mb={{xs: 6, md: 5}}
        >
          <GridContainer>
            <Grid item xs={12}>
              <AppTextInput
                fullWidth
                variant="outlined"
                label="Nome"
                value={fname}
                onChange={e => {
                  setFname(e.target.value);
                  setFnameError("");
                }}
                helperText={fnameError}
              />
            </Grid>
          </GridContainer>
        </Box>
        <Box
          display="flex"
          flexDirection={{xs: "column", md: "row"}}
          alignItems="center"
          mb={{xs: 6, md: 5}}
        >
          <GridContainer>
            <Grid item xs={12}>
              <AppTextInput
                fullWidth
                variant="outlined"
                label="email"
                value={email}
                onChange={e => {
                  setEmail(e.target.value);
                  setEmailError("");
                }}
                helperText={emailError}
              />
            </Grid>
          </GridContainer>
        </Box>
        <Box
          display="flex"
          flexDirection={{xs: "column", md: "row"}}
          alignItems="center"
          mb={{xs: 6, md: 5}}
        >
          <GridContainer>
            <Grid item xs={12}>
              <AppTextInput
                fullWidth
                variant="outlined"
                disabled={currentLead}
                label={currentLead ? "" : "Telefone"}
                value={telefone}
                onChange={number => {
                  setTelefone(number);
                  setTelefoneError("");
                }}
                helperText={telefoneError}
                InputProps={{
                  inputComponent: NumberFormatCustom
                }}
              />
            </Grid>
          </GridContainer>
        </Box>
        <Box
          display="flex"
          flexDirection={{xs: "column", md: "row"}}
          alignItems="center"
          mb={{xs: 6, md: 5}}
        >
          <GridContainer>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Selecione a origem
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={origem}
                  error={origemError}
                  onChange={e => {
                    setOrigem(e.target.value);
                    setOrigemError(false);
                  }}
                >
                  {ORIGENS.map((row, key) => (
                    <MenuItem key={key} value={row}>
                      {row}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </GridContainer>
        </Box>
        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button onClick={handleDialog}>Cancelar</Button>
          <Box ml={2}>
            {loader ? (
              <Loader type="ball-pulse-sync" />
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={checkValidations}
              >
                Salvar
              </Button>
            )}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CreateLead;

CreateLead.prototype = {
  open: PropTypes.bool.isRequired,
  handleDialog: PropTypes.func,
  selectedContact: PropTypes.object
};

CreateLead.defaultProps = {
  selectedContact: null
};
