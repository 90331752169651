import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions'
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button'

const useStyles = makeStyles({
    root: {
        maxWidth: 345,
    },
});

export default function ImgMediaCard({ sessao, handleSelectSessao }) {
    const classes = useStyles();
    return (
        <Card className={classes.root}>
            <CardActionArea>
                <CardMedia
                    component="img"
                    alt="Whatsapp Business"
                    height="140"
                    image="/images/dashboard.png"
                    title="Whatsapp Business"
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        {sessao._id.nome}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        Número: {sessao.number}<br />
                        Phone ID: {sessao.from_phone_id}<br />
                        Account ID: {sessao.account_id}<br />
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button size="small" color="primary" onClick={() => handleSelectSessao(sessao)}>
                        Templates
                    </Button>
                </CardActions>
            </CardActionArea>
        </Card>
    );
}