import React, { useEffect, useState } from "react";
import GridContainer from "../../../@jumbo/components/GridContainer";
import PageContainer from "../../../@jumbo/components/PageComponents/layouts/PageContainer";
import Box from "@material-ui/core/Box";
import IntlMessages from "../../../@jumbo/utils/IntlMessages";
import Grid from "@material-ui/core/Grid";
import ProjectCounterCard from "./ProjectCounterCard";
import FilesCounterCard from "./FilesCounterCard";
import TasksCounterCard from "./TasksCounterCard";
import TeamsCounterCard from "./TeamsCounterCard";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Badge from "@mui/material/Badge";
import Chart from "./Charts/Atendentes";
import PopularAgents from "./PopularAgents";
import Sessoes from "./Sessoes";
import axios from "services/auth/jwt/config";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import { URL_CHAT } from "util/socket";
import Fab from "@material-ui/core/Fab";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import Tooltip from "@material-ui/core/Tooltip";
import NotificationDialog from "@jumbo/components/DialogNotification";
import FullDetailNotification from "@jumbo/components/DialogNotification/DialogFullDescription";


const breadcrumbs = [
  { label: "Home", link: "/" },
  { label: "Dashboards", isActive: true }
];

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  },
  orderLg2: {
    [theme.breakpoints.up("lg")]: {
      order: 2
    }
  },
  orderLg1: {
    [theme.breakpoints.up("lg")]: {
      order: 1
    }
  },
  orderLg3: {
    [theme.breakpoints.up("lg")]: {
      order: 3
    }
  },
  orderLg4: {
    [theme.breakpoints.up("lg")]: {
      order: 4
    }
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(8),
    right: theme.spacing(8)
  }
}));

const Dashboards = ({ match }) => {
  const classes = useStyles();
  const [data, setData] = useState({
    contatos: 0,
    atendentes: [],
    conversas_andamento: [],
    conversas_finalizadas: [],
    sessoes_whatsapp: []
  });
  const [atendentes, setAtendentes] = useState([]);
  const [loader, setLoader] = useState(true);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [currentNotification, setCurrentNotification] = useState(null);
  const [countNotification, setCountNotification] = useState(0);

  const token = localStorage.getItem("token");
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  const handleChangeNotifications = () =>
    setOpenNotifications(!openNotifications);

  useEffect(() => {
    axios
      .get("dashboard")
      .then(success => {
        setAtendentes(success.data.atendentes);
        setData(success.data);
        setLoader(false);
      })
      .catch(err => {
        setLoader(false);
      });
  }, []);

  useEffect(() => {
    axios
      .get("/notifications/count")
      .then(success => {
        setCountNotification(success.data.count);
      })
      .catch(err => err);
  }, []);

  return (
    <PageContainer
      heading={<IntlMessages id="pages.dashboards" />}
      breadcrumbs={breadcrumbs}
    >
      <GridContainer>
        <Grid item xs={12}>
          <Box>
            <IntlMessages id="pages.dashboards.description" />
            <div>
              <a href={URL_CHAT} target="_blank" rel="noreferrer">
                Acessar Chat Atendentes
              </a>
            </div>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <GridContainer>
            <Grid item xs={12} sm={6} md={3}>
              <TasksCounterCard contatos={data.contatos} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TeamsCounterCard atendentes={data.atendentes.length} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <ProjectCounterCard
                conversas_ativas={data.conversas_andamento}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FilesCounterCard
                conversas_finalizadas={data.conversas_finalizadas}
              />
            </Grid>
          </GridContainer>
        </Grid>
        <Grid item xs={12} lg={12} xl={12} className={classes.orderLg1}>
          <Box pb={6}>
            <PopularAgents atendentes={atendentes} />
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.orderLg2}>
          <Sessoes sessoes_whatsapp={data.sessoes_whatsapp} />
        </Grid>

        <Grid item xs={12} className={classes.orderLg3}>
          <Chart atendentes={atendentes} />
        </Grid>
        <Grid item xs={12} className={classes.orderLg4}>
          {
            // <ChartConversas />
          }
        </Grid>
      </GridContainer>
      <Backdrop className={classes.backdrop} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Tooltip title="Notificações" aria-label="add">
        <Fab
          className={classes.fab}
          color="secondary"
          aria-label="edit"
          onClick={handleChangeNotifications}
        >
          <Badge badgeContent={countNotification} color="info">
            <NotificationsActiveIcon />
          </Badge>
        </Fab>
      </Tooltip>
      <NotificationDialog
        open={openNotifications}
        handleClose={handleChangeNotifications}
        setCurrentNotification={setCurrentNotification}
      />
      {currentNotification && (
        <FullDetailNotification
          currentNotification={currentNotification}
          handleClose={() => setCurrentNotification(null)}
        />
      )}
    </PageContainer>
  );
};

export default Dashboards;
