import React, {useState, useEffect} from "react";
import CmtCard from "@coremat/CmtCard";
import {Box} from "@material-ui/core";
import {AiFillPlayCircle} from "react-icons/ai";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CmtImage from "@coremat/CmtImage";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import {FaRobot} from "react-icons/fa";
import Tooltip from "@material-ui/core/Tooltip";
import {IoLogoWhatsapp} from "react-icons/io";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import {BsGearFill} from "react-icons/bs";

const useStyles = makeStyles(theme => ({
  cardRoot: {
    height: "100%",
    position: "relative",
    "&:hover $actionHoverRoot": {
      left: -25
    }
  },
  userImgRoot: {
    width: 120,
    height: 120,
    "& img": {
      height: "100%"
    }
  },
  actionRoot: {
    position: "absolute",
    right: 0,
    top: 0,
    zIndex: 1,
    height: "100%",
    width: 25,
    color: "#457b9d",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    "& > .MuiSvgIcon-root": {
      marginLeft: -15
    }
  },
  actionHoverRoot: {
    backgroundColor: "#457b9d",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    width: 140,
    height: 140,
    borderRadius: "50%",
    position: "absolute",
    left: 10,
    top: -9,
    zIndex: 1,
    padding: 12,
    transition: "all 0.3s ease"
  }
}));

const UserSummery = ({
  robo,
  handleEditar,
  handleDelete,
  handleConfigurar,
  handleChatTest,
  handleCloseChatTest
}) => {
  const classes = useStyles();

  return (
    <CmtCard className={classes.cardRoot}>
      <Box display="flex" alignItems="center" height={1}>
        <Box className={classes.userImgRoot}>
          <CmtImage src={"/images/chatbot_3.jpg"} />
        </Box>
        <Box ml={{xs: 5, xl: 8}}>
          <Typography component="div" variant="h4">
            {robo.nome}
          </Typography>
          <Box component="p">
            {robo.departamento.map(
              (dep, index) => (index === 0 ? `${dep.nome}` : `, ${dep.nome}`)
            )}
          </Box>
          <Box paddingTop="15px">
            <Tooltip title="Configurar">
              <IconButton
                aria-label="config"
                onClick={() => {
                  handleCloseChatTest();
                  handleConfigurar(robo);
                }}
              >
                <BsGearFill size="20px" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Editar">
              <IconButton
                aria-label="delete"
                onClick={() => {
                  handleCloseChatTest();
                  handleEditar(robo);
                }}
              >
                <EditIcon size="20px" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Excluir">
              <IconButton
                aria-label="delete"
                onClick={() => {
                  handleCloseChatTest();
                  handleDelete(robo);
                }}
              >
                <DeleteIcon size="20px" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Testar">
              <IconButton
                aria-label="delete"
                onClick={() => {
                  handleChatTest(robo);
                }}
              >
                <AiFillPlayCircle size="20px" color="grey" />
              </IconButton>
            </Tooltip>
          </Box>
          <Box className={classes.actionRoot}>
            <MoreVertIcon style={{color: "#457b9d"}} />
            <Box className={classes.actionHoverRoot}>
              <FaRobot size="25px" style={{color: "#fff"}} />
            </Box>
          </Box>
        </Box>
      </Box>
    </CmtCard>
  );
};

export default UserSummery;
