import * as React from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Skeleton from '@mui/material/Skeleton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import Tooltip from '@mui/material/Tooltip';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CardActions from '@mui/material/CardActions';

import PhonelinkIcon from '@mui/icons-material/Phonelink';
import PhonelinkOffIcon from '@mui/icons-material/PhonelinkOff';

const MySwal = withReactContent(Swal);

function Media({ loading = false, imgIcon, img, title, subtitle, body, id, handleRemove, handleShow, data, label,activated, handleChangeActivated }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => setAnchorEl(null);

    const handleRemoveAction = () => {
        setAnchorEl(null);
        MySwal.fire({
            title: "Atenção!",
            text: "Ao remover esta integração, todas as funcionalidades dependentes dela serão desabilitadas.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Continuar",
            cancelButtonText: "Voltar",
            reverseButtons: true
        }).then(result => {
            if (result.value) {
                handleRemove(id)
            }
        });
    };

    const handleShowAction = () => {
        setAnchorEl(null);
        handleShow(data, label)
    }

    return (
        <Card sx={{ maxWidth: 400 }}>
            <CardHeader
                avatar={
                    loading ? (
                        <Skeleton animation="wave" variant="circular" width={40} height={40} />
                    ) : (
                        <Avatar
                            alt="integracao"
                            //   src="https://pbs.twimg.com/profile_images/877631054525472768/Xp5FAPD5_reasonably_small.jpg"
                            src={imgIcon}
                        />
                    )
                }
                action={
                    loading ? null : (
                        <>
                            <IconButton aria-label="settings" onClick={handleClick}>
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem onClick={handleShowAction}>
                                    <ListItemIcon>
                                        <RemoveRedEyeIcon fontSize="small" />
                                    </ListItemIcon>
                                    Visualizar
                                </MenuItem>
                                <MenuItem onClick={handleRemoveAction}>
                                    <ListItemIcon>
                                        <DeleteOutlineIcon fontSize="small" />
                                    </ListItemIcon>
                                    Remover
                                </MenuItem>
                            </Menu>
                        </>
                    )
                }
                title={
                    loading ? (
                        <Skeleton
                            animation="wave"
                            height={10}
                            width="80%"
                            style={{ marginBottom: 6 }}
                        />
                    ) : (
                        title
                    )
                }
                subheader={
                    loading ? (
                        <Skeleton animation="wave" height={10} width="40%" />
                    ) : (
                        subtitle
                    )
                }
            />
            {loading ? (
                <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />
            ) : (
                <CardMedia
                    component="img"
                    height="140"
                    // image="https://pi.tedcdn.com/r/talkstar-photos.s3.amazonaws.com/uploads/72bda89f-9bbf-4685-910a-2f151c4f3a8a/NicolaSturgeon_2019T-embed.jpg?w=512"
                    image={img}
                    alt="integracao"
                />
            )}

            <CardContent style={{ height: 90 }}>
                {loading ? (
                    <React.Fragment>
                        <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
                        <Skeleton animation="wave" height={10} width="80%" />
                    </React.Fragment>
                ) : (
                    <Typography variant="body2" color="text.secondary" component="p" align='center'>
                        {
                            body
                        }
                    </Typography>
                )}
            </CardContent>
            <CardActions disableSpacing>
                <Tooltip title={activated ? "Desativar" : "Ativar"}>
                    <IconButton aria-label={activated ? "Desativar" : "Ativar"} color={activated ? 'success' : 'error'} onClick={() => handleChangeActivated(data)}>
                        {activated ? <PhonelinkIcon /> : <PhonelinkOffIcon />}
                    </IconButton>
                </Tooltip>
                <IconButton aria-label="Tutorial" color='info' href='https://multdesk.readme.io' target='_blank'>
                    <OpenInNewIcon />
                </IconButton>
            </CardActions>
        </Card>
    );
}

Media.propTypes = {
    loading: PropTypes.bool,
};

export default Media
