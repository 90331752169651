import React, {useState} from "react";
import CmtGridView from "@coremat/CmtGridView";
import PictureItem from "./PictureItem";
import makeStyles from "@material-ui/core/styles/makeStyles";
import InfiniteScroll from "react-perfect-scrollbar";

const useStyles = makeStyles(theme => ({
  scrollBar: {
    height: 600,
    overflow: "auto"
  }
}));

const UserPhotos = ({
  files,
  handleDelete,
  handleCopy,
  isDialog,
  selectedFile,
  handleNextPage
}) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <InfiniteScroll
        className={classes.scrollBar}
        onYReachEnd={handleNextPage}
      >
        <CmtGridView
          data={files}
          itemPadding={10}
          responsive={{
            xs: 2,
            sm: 4,
            md: 6,
            lg: 8,
            xl: 10
          }}
          renderRow={(item, index) => (
            <PictureItem
              key={index}
              item={item}
              handleDelete={handleDelete}
              handleCopy={handleCopy}
              isDialog={isDialog}
              selectedFile={selectedFile}
            />
          )}
        />
      </InfiniteScroll>
    </React.Fragment>
  );
};

export default UserPhotos;
