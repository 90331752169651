import React, {useState, useEffect} from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import {makeStyles} from "@material-ui/core";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

import Box from "@mui/material/Box";

import CardNotification from "./Card";
import axios from "services/auth/jwt/config";

const Transition = React.forwardRef(function Transition(props, ref){
  return <Slide direction="left" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  dialog: {
    width: "30%"
  }
});

const NotificationDialog = ({open, handleClose, setCurrentNotification}) => {
  const classes = useStyles();

  const [ notification, setNotifications ] = useState([]);
  const [ loader, setLoader ] = useState(true);

  useEffect(
    () => {
      setLoader(true);
      axios
        .get("/notifications")
        .then(success => {
          setNotifications(success.data.notifications);
          setLoader(false);
        })
        .catch(err => {
          setLoader(false);
        });
    },
    [ open ]
  );

  return (
    <div class="float-right">
      <Dialog
        maxWidth="sm"
        scroll="paper"
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        className={classes.dialog}
        style={{
          left: "unset"
        }}
      >
        <AppBar sx={{position: "relative"}}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
              Notificações
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          {loader ? (
            <Box sx={{display: "flex"}}>
              <CircularProgress />
            </Box>
          ) : notification.length === 0 ? (
            <DialogContentText>Não há Notificações.</DialogContentText>
          ) : (
            <List>
              {notification.map(row => (
                <ListItem>
                  <CardNotification
                    data={row}
                    setCurrentNotification={setCurrentNotification}
                  />
                </ListItem>
              ))}
            </List>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default NotificationDialog;
