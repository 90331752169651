import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router";
import PageLoader from "../../@jumbo/components/PageComponents/PageLoader";
import Profile from "./Profile";
import WhatsApp from "./WhatsApp";
import Atendimento from "./Atendimento";
import Files from "./Files";
import Leads from "./Leads";
import Robo from "./Robo";
import Invoice from "./Invoice";
import Dashboards from "./Dashboards";
import Planos from "./Planos";
import Api from "./Api"
import Integracao from "./Integracao"


const ExtraPages = ({ match }) => {
  const requestedUrl = match.url.replace(/\/$/, "");

  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Route path={`${requestedUrl}/invoice`} component={Invoice} />
        <Route path={`${requestedUrl}/dashboards`} component={Dashboards} />
        <Route path={`${requestedUrl}/profile`} component={Profile} />
        <Route path={`${requestedUrl}/whatsapp`} component={WhatsApp} />
        <Route path={`${requestedUrl}/atendimento`} component={Atendimento} />
        <Route path={`${requestedUrl}/files`} component={Files} />
        <Route path={`${requestedUrl}/leads`} component={Leads} />
        <Route path={`${requestedUrl}/robo`} component={Robo} />
        <Route path={`${requestedUrl}/planos`} component={Planos} />
        <Route path={`${requestedUrl}/api`} component={Api} />
        <Route path={`${requestedUrl}/integracao`} component={Integracao} />
        <Route component={lazy(() => import("./404"))} />
      </Switch>
    </Suspense>
  );
};

export default ExtraPages;
