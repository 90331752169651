import React, {useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import CheckedListHeader from "./CheckedListHeader";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import {DataGrid} from "@mui/x-data-grid";
import Moment from "moment";
import {setFilterType} from "redux/actions/ContactApp";

const columns = [
  {field: "name", headerName: "Nome", width: 350},
  {field: "email", headerName: "E-mail", width: 250, sortable: false},
  {
    field: "phones",
    headerName: "Telefone",
    width: 150,
    valueGetter: params =>
      params.value.length > 0 ? params.value[0].phone : ""
  },
  {
    field: "createdAt",
    headerName: "Dt. inclusão",
    type: "date",
    width: 150,
    valueGetter: params => Moment(params.value).format("DD/MM/YYYY  HH:mm")
  },
  {field: "_id", headerName: "id", width: 250}
];

const ListTableView = ({
  checkedContacts,
  handleCellCheckBox,
  handleHeaderCheckBox,
  handleSortModelChange,
  updateCheckedContacts,
  onShowContactDetail,
  onClickEditContact
}) => {
  const dispatch = useDispatch();
  const {contactsList, totalContacts, filterType} = useSelector(
    ({contactApp}) => contactApp
  );

  return (
    <React.Fragment>
      {checkedContacts.length > 0 && (
        <CheckedListHeader
          checkedContacts={checkedContacts}
          handleHeaderCheckBox={handleHeaderCheckBox}
          updateCheckedContacts={updateCheckedContacts}
        />
      )}
      <Box className="Cmt-table-responsive">
        <DataGrid
          rows={contactsList}
          columns={columns}
          checkboxSelection
          autoHeight
          density="compact"
          paginationMode="server"
          columnBuffer={2}
          pageSize={50}
          rowCount={totalContacts}
          rowsPerPageOptions={[ 50 ]}
          disableSelectionOnClick
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          onRowClick={({row}) => onClickEditContact(row)}
          onSelectionModelChange={ids => {
            handleCellCheckBox(ids);
          }}
          page={filterType.page - 1}
          onPageChange={page => {
            var copyFilter = {...filterType};
            copyFilter.page = page + 1;
            dispatch(setFilterType(copyFilter));
          }}
        />
      </Box>
    </React.Fragment>
  );
};

export default ListTableView;

ListTableView.prototype = {
  checkedContacts: PropTypes.array,
  handleCellCheckBox: PropTypes.func,
  handleHeaderCheckBox: PropTypes.func,
  updateCheckedContacts: PropTypes.func,
  onShowContactDetail: PropTypes.func,
  onClickEditContact: PropTypes.func
};

ListTableView.defaultProps = {
  checkedContacts: []
};
