//For expanding sidebar
import {
  ADD_LABEL,
  CREATE_CONTACT,
  DELETE_CONTACT,
  DELETE_LABEL_ITEM,
  GET_CONTACT_COUNTS,
  GET_CONTACTS_LIST,
  GET_LABELS_LIST,
  SET_CURRENT_CONTACT,
  SET_FILTER_TYPE,
  TOGGLE_SIDEBAR_COLLAPSED,
  UPDATE_CONTACT,
  UPDATE_CONTACT_LABEL,
  UPDATE_LABEL_ITEM,
  UPDATE_STARRED_STATUS,
  UPDATE,
  GET_CONTACT_COUNTS_DUPLICATES,
  DELETE_CONTACT_COUNTS_DUPLICATES
} from "@jumbo/constants/ActionTypes";
import {fetchError, fetchStart, fetchSuccess} from "./Common";
import axios from "services/auth/jwt/config";

//For expanding sidebar
export const toggleExpandSidebar = value => {
  return dispatch => {
    dispatch({
      type: TOGGLE_SIDEBAR_COLLAPSED,
      payload: value
    });
  };
};

//For setting Filtertype
export const setFilterType = filterType => {
  return {
    type: SET_FILTER_TYPE,
    payload: filterType
  };
};

//for getting labels list
export const getLabelsList = () => {
  return dispatch => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    dispatch(fetchStart());
    axios
      .get("/contact/labels")
      .then(data => {
        if (data.status === 200) {
          // dispatch(fetchSuccess());
          dispatch({type: GET_LABELS_LIST, payload: data.data});
        } else {
          dispatch(fetchError("Erro ao buscar grupos!"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Erro ao buscar grupos!"));
      });
  };
};

//for adding new label
export const addNewLabel = label => {
  return dispatch => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    dispatch(fetchStart());
    axios
      .post("/contact/labels", {label})
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({type: ADD_LABEL, payload: data.data});
        } else {
          dispatch(fetchError("Erro  ao criar grupo!"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Erro  ao criar grupo!"));
      });
  };
};

//For Deleting Label
export const deleteLabel = labelId => {
  return dispatch => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    dispatch(fetchStart());
    axios
      .post("/contact/labels/delete", {labelId})
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({type: DELETE_LABEL_ITEM, payload: labelId});
        } else {
          dispatch(fetchError("Erro ao deletar grupo!"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Erro ao deletar grupo!"));
      });
  };
};

//For Editing Label
export const updateLabel = label => {
  return dispatch => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    dispatch(fetchStart());
    axios
      .put("/contact/labels", {label})
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({type: UPDATE_LABEL_ITEM, payload: label});
        } else {
          dispatch(fetchError("Erro ao atualizar grupo!"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Erro ao atualizar grupo!"));
      });
  };
};

export const setContactList = data => ({
  type: GET_CONTACTS_LIST,
  payload: data
});

//for getting contacts list
export const getContactsList = params => {
  return dispatch => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    dispatch(fetchStart());
    axios
      .get("/contact", {params})
      .then(data => {
        if (data.status === 200) {
          dispatch({type: GET_CONTACTS_LIST, payload: data.data.contactsList});
          dispatch(fetchSuccess());
        } else {
          dispatch(fetchError("Não há contatos!"));
        }
      })
      .catch(error => {
        console.log(error);
        dispatch(fetchError("Erro ao buscar contatos!"));
      });
  };
};

export const setCurrentContact = contact => {
  return dispatch => {
    dispatch({
      type: SET_CURRENT_CONTACT,
      payload: contact
    });
  };
};

//for creating new contact
export const createContact = contact => {
  return dispatch => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    dispatch(fetchStart());
    axios
      .post("/contact", {contact})
      .then(data => {
        if (data.status === 200) {
          dispatch({type: CREATE_CONTACT, payload: data.data});
          dispatch(fetchSuccess());
        } else {
          dispatch(fetchError("Erro ao criar contato!"));
        }
      })
      .catch(error => {
        dispatch(fetchError(error.response.data.message));
      });
  };
};

//for updating contact through detail page
export const onUpdateContact = contact => {
  return dispatch => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    dispatch(fetchStart());
    axios
      .put("/contact", {contact})
      .then(data => {
        if (data.status === 200) {
          dispatch({type: UPDATE_CONTACT, payload: data.data});
          dispatch(fetchSuccess());
        } else {
          dispatch(fetchError("Erro ao atualziar contato!"));
        }
      })
      .catch(error => {
        dispatch(fetchError(error.response.data.message));
      });
  };
};

//for updating contacts starred status(through listing)
export const updateStarredStatus = (contactIds, status) => {
  return dispatch => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    dispatch(fetchStart());
    axios
      .put("/contact/update-starred", {contactIds, status})
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({
            type: UPDATE_STARRED_STATUS,
            payload: {contactIds, status}
          });
        } else {
          dispatch(fetchError("Erro ao marcar como favorito"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Erro ao marcar como favorito"));
      });
  };
};

//for updating mails folder(through listing)
export const deleteContact = contactIds => {
  return dispatch => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    dispatch(fetchStart());
    axios
      .put("/contact/delete", {contactIds})
      .then(data => {
        if (data.status === 200) {
          if (data.data.count_contacts_campanhas > 0) {
            dispatch(
              fetchError(
                "Alguns contatos não podem ser removidos, primeiro remova-os das camapanhas!"
              )
            );
          } else {
            dispatch(fetchSuccess());
            dispatch({type: DELETE_CONTACT, payload: contactIds});
          }
        } else {
          dispatch(fetchError("Erro ao excluir contato!"));
        }
      })
      .catch(error => {
        dispatch(fetchError("Erro ao excluir contato!"));
      });
  };
};

//for updating contacts label(through listing)
export const updateContactsLabel = (contactIds, label) => {
  return dispatch => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    dispatch(fetchStart());

    axios
      .put("/contact/update-label", {contactIds, label})
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({type: UPDATE_CONTACT_LABEL, payload: data.data});
        } else {
          dispatch(fetchError("Erro ao adicionar grupo ao contato!"));
        }
      })
      .catch(error => {
        console.log(error);
        dispatch(fetchError("Erro ao adicionar grupo ao contato!"));
      });
  };
};

//for getting contact categories(in sidebar) count
export const getContactCounts = () => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  return dispatch => {
    axios
      .get("/contact/counter")
      .then(data => {
        if (data.status === 200) {
          dispatch({type: GET_CONTACT_COUNTS, payload: data.data});
        }
      })
      .catch(error => {
        dispatch(fetchError("Something went wrong"));
      });
  };
};

export const getContactCountsDuplicates = () => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  return dispatch => {
    axios
      .get("/contact/counterDuplicates")
      .then(data => {
        if (data.status === 200) {
          dispatch({type: GET_CONTACT_COUNTS_DUPLICATES, payload: data.data});
        }
      })
      .catch(error => {
        dispatch(fetchError(error.response.data.message));
      });
  };
};

export const DeleteContactCountsDuplicates = () => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  return dispatch => {
    axios
      .delete("/contact/counterDuplicates")
      .then(data => {
        if (data.status === 200) {
          dispatch({
            type: DELETE_CONTACT_COUNTS_DUPLICATES,
            payload: data.data
          });
        }
      })
      .catch(error => {
        dispatch(fetchError(error.response.data.message));
      });
  };
};

export const update = () => ({
  type: UPDATE
});
