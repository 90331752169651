import React from "react";
import {
  Box,
  Typography
} from "@material-ui/core";
import CmtAvatar from "../../../../@coremat/CmtAvatar";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useDispatch } from "react-redux";
import { AuhMethods } from "../../../../services/auth";
import { CurrentAuthMethod } from "../../../constants/AppConstants";
import LogoutIcon from '@mui/icons-material/Logout';
import { useSelector } from "react-redux";
import Tooltip from '@mui/material/Tooltip';

const useStyles = makeStyles(theme => ({
  root: {
    padding: "30px 16px 0px 16px",
    borderBottom: `solid 1px ${theme.palette.sidebar.borderColor}`
  },
  userInfo: {
    paddingTop: 24,
    transition: "all 0.1s ease",
    height: 75,
    opacity: 1,
    ".Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &": {
      height: 0,
      paddingTop: 0,
      opacity: 0,
      transition: "all 0.3s ease"
    }
  },
  userTitle: {
    color: theme.palette.sidebar.textDarkColor,
  },
  userSubTitle: {
    fontSize: 10,
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 0.25
  }
}));

const SidebarHeader = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { authUser } = useSelector(({ auth }) => auth);

  const onLogoutClick = () => {
    dispatch(AuhMethods[CurrentAuthMethod].onLogout());
  };

  if (!authUser) {
    return false;
  }

  return (
    <Box className={classes.root}>
      <CmtAvatar src={authUser.photo} alt={authUser.nome} />
      <Box className={classes.userInfo}>
        <Box
          className="pointer"
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Box mr={2}>
            <Typography
              className={classes.userTitle}
              component="h3"
              variant="h6"
            >
              {authUser.nome.length >= 18 ? authUser.nome.slice(0, 18) : authUser.nome}
            </Typography>
          </Box>
          <Tooltip title="Sair"  placement="top-start">
            <LogoutIcon fontSize="small" onClick={onLogoutClick}/>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );
};

export default SidebarHeader;
