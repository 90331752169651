import React from "react";
import AgentItem from "./AgentItem";
import CmtList from "../../../../@coremat/CmtList";
import PerfectScrollbar from "react-perfect-scrollbar";

const AgentsList = ({sessoes_whatsapp}) => {
  return (
    <PerfectScrollbar style={{width: "100%"}}>
      <CmtList
        data={sessoes_whatsapp}
        style={{
          display: "flex",
          flexDirection: "row",
          marginLeft: -10,
          marginRight: -10
        }}
        renderRow={(item, index) =>
          item._id ? <AgentItem key={index} item={item} /> : null}
      />
    </PerfectScrollbar>
  );
};

export default AgentsList;
