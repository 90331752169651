import React, { useEffect, useState } from "react";
import PageContainer from "@jumbo/components/PageComponents/layouts/PageContainer";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import CmtCard from "@coremat/CmtCard";
import CmtCardContent from "@coremat/CmtCard/CmtCardContent";
import Alert from "@material-ui/lab/Alert";
import UserDetail from "./UserDetail";
import axios from "services/auth/jwt/config";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { fade, Typography } from "@material-ui/core";
import { blue, pink } from "@material-ui/core/colors";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import { NavLink } from "react-router-dom";
import ItemCell from "../../Atendimento/setor/ItemCell";
import CmtList from "@coremat/CmtList";
import { MdWork } from "react-icons/md";
import { Search as SearchIcon } from '@mui/icons-material';
import { InputAdornment } from '@material-ui/core';

const breadcrumbs = [
  { label: "Home", link: "/" },
  { label: "WhatsApp" },
  { label: "QrCode", isActive: true }
];

const notifications = [
  {
    id: 2,
    bgColor: "#F2E7FE",
    color: "#7F39FB",
    content:
      "Não há departamentos cadastrados, para começar adicione um novo departamento!",
    showIcon: false,
    icon: <MdWork />
  }
];


const useStyles = makeStyles(theme => ({
  cardRoot: {
    height: "100%",
  },
  selectBoxRoot: {
    marginBottom: 6,
    display: "inline-block",
    "& .MuiInput-underline:before, & .MuiInput-underline:after": {
      display: "none"
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent"
    },
    "& .MuiInputBase-root": {
      fontSize: 14,
      color: theme.palette.text.secondary
    }
  },
  addressTitle: {
    fontWeight: theme.typography.fontWeightRegular,
    [theme.breakpoints.up("lg")]: {
      fontSize: 18
    }
  },
  socialLink: {
    display: "flex",
    alignItems: "center",
    marginLeft: -8,
    marginRight: -8
  },
  socialLinkCol: {
    paddingLeft: 8,
    paddingRight: 8,
    "& .btn": {
      backgroundColor: fade(theme.palette.primary.main, 0.1),
      color: theme.palette.primary.main,
      padding: 6,
      "& .MuiSvgIcon-root": {
        fontSize: 20
      },
      "&.twitter": {
        backgroundColor: fade(blue[500], 0.1),
        color: blue[500]
      },
      "&.instagram": {
        backgroundColor: fade(pink[500], 0.1),
        color: pink[500]
      },
      "&.linkedin": {
        backgroundColor: fade(blue[500], 0.1),
        color: blue[500]
      }
    }
  },
  contactRoot: {
    "& .MuiSvgIcon-root": {
      fontSize: 20
    }
  },
  formControl: {
    margin: theme.spacing(2)
  },
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2)
    }
  }
}));

const QrCode = () => {
  const classes = useStyles();
  const [setores, setSetores] = useState([]);
  const [setor, setSetor] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios
      .get("atendimento/departamento")
      .then(succes => {
        setSetores(succes.data);
        setLoading(false);
      })
      .catch(error => error);
  }, []);

  return (
    <PageContainer
      heading={<IntlMessages id="pages.whatsapp.qrCode" />}
      breadcrumbs={breadcrumbs}
    >
      <CmtCard className={classes.cardRoot}>
        <CmtCardContent>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={6}>
              <Grid item xs={12} sm={12} md={12}>
                <p style={{ textAlign: "center", color: 'grey', margin: '8px' }}>
                  <h1>INSTRUÇÕES</h1>
                </p>
                <p style={{ textAlign: "justify", color: 'grey', fontSize: '12px' }}>
                  1. Abra o WhatsApp no seu celular
                  <br />
                  2. Toque no ícone do WhatsApp para abrir o aplicativo.<br />
                  3. Acesse o menu de dispositivos conectados:<br /><br />

                  4. No Android: Toque nos três pontos no canto superior direito e selecione "Dispositivos Conectados"<br />
                  No iPhone: Toque em "Configurações" no canto inferior direito e selecione "Dispositivos Conectados"<br /><br />
                  Adicionar um novo dispositivo:<br />

                  5. Toque em "Conectar um dispositivo"<br />
                  6. Escanear o QR Code:<br />
                  O WhatsApp abrirá a câmera. Aponte a câmera do celular para o QR Code exibido na tela do computador<br />
                </p>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Box className={classes.root} marginTop='10px' marginBottom='10px'>
                  <Alert severity="warning">
                    Não é possível realizar a leitura do QrCode para mais de um departamento utilizando o mesmo número.
                  </Alert>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Selecione um Setor
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    variant="standard"
                    value={setor.nome}
                    disabled={loading}
                    onChange={e => {
                      setSetor({ _id: e.target.value, nome: e.target.key });
                    }}
                    endAdornment={
                      loading ? (
                        <InputAdornment position="end">
                          <CircularProgress size={24} />
                        </InputAdornment>
                      ) : (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      )
                    }
                  >
                    {setores.map((row, key) => (
                      <MenuItem key={row.nome} value={row._id}>
                        {row.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {!loading && setores.length === 0 &&
                <Grid item xs={12} sm={12} md={12}>
                  <NavLink to={"/app/atendimento/setor"}>
                    <CmtList
                      data={notifications}
                      renderRow={(item, index) => <ItemCell item={item} key={index} />}
                    />
                  </NavLink>
                </Grid>
              }
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <UserDetail setor={setor} setores={setores} />
            </Grid>
          </Grid>
        </CmtCardContent>
      </CmtCard>
    </PageContainer >
  );
};

export default QrCode;
