import React, {useState, useEffect} from "react";
import {Launcher} from "popup-chat-react";
import axios from "services/auth/jwt/config";

const Chat = ({handleCloseChatTest, currentBot, openChatTest}) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  const [ state, setState ] = useState({
    messageList: [],
    newMessagesCount: 0,
    isOpen: false,
    fileUpload: true
  });

  const onMessageWasSent = message => {
    var chat = {
      ...state,
      messageList: [ ...state.messageList, message ]
    };

    setState(state => ({
      ...state,
      messageList: [ ...state.messageList, message ]
    }));

    axios
      .post("bot/resposta", {
        chatId: currentBot._id,
        mensagem: message.data.text,
        departamento: currentBot.departamento.map(row => row._id)[0],
        origem: "site-teste",
        userId: currentBot.userId,
        type: "text"
      })
      .then(success => {
        success.data.mensagens.map(row => {
          if (row.type === "file") {
            console.log(row);

            var fileName = row.mensagem.split("/");
            fileName = fileName[fileName.length - 1];

            chat.messageList.push({
              author: "them",
              type: row.type,
              data: {
                url: row.mensagem,
                fileName: fileName
              }
            });
          } else {
            // verifico se tem opções
            var mensagem;

            if (row.options) {
              mensagem = row.mensagem + "\n\n";
              row.options.map(
                (option, index) =>
                  (mensagem += `${index + 1} - ${option.option}\n`)
              );
            } else {
              mensagem = row.mensagem;
            }

            chat.messageList.push({
              author: "them",
              type: row.type,
              data: {
                text: mensagem
              }
            });
          }
        });

        setState(chat);
      })
      .catch(error => {});
  };

  function onFilesSelected(fileList){
    const objectURL = window.URL.createObjectURL(fileList[0]);

    setState(state => ({
      ...state,
      messageList: [
        ...state.messageList,
        {
          type: "file",
          author: "me",
          data: {
            url: objectURL,
            fileName: fileList[0].name
          }
        }
      ]
    }));
  }

  function onClick(){
    if (openChatTest) {
      setState(state => ({
        ...state,
        isOpen: !openChatTest,
        newMessagesCount: 0
      }));

      axios
        .post("bot/finalizarTestes", {
          origem: "site-teste",
          chatId: currentBot._id,
          userId: currentBot.userId
        })
        .then(success => success)
        .catch(err => console.log(err));

      handleCloseChatTest();
    }
  }

  useEffect(() => {}, []);

  return (
    <div style={{zIndex: "1060"}}>
      <Launcher
        agentProfile={{
          teamName: currentBot ? currentBot.nome : null,
          imageUrl: "/images/chatbot_4.png"
        }}
        launcherColor="##457b9d"
        onMessageWasSent={onMessageWasSent}
        onFilesSelected={onFilesSelected}
        messageList={state.messageList}
        newMessagesCount={state.newMessagesCount}
        onClick={onClick}
        isOpen={openChatTest}
        showEmoji
        fileUpload={state.fileUpload}
        placeholder="Digite sua mensagem..."
      />
    </div>
  );
};

export default Chat;
