import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Box, fade } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import IntlMessages from "../../../utils/IntlMessages";
import Button from "@material-ui/core/Button";
import { AuhMethods } from "../../../../services/auth";
import ContentLoader from "../../ContentLoader";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CmtImage from "../../../../@coremat/CmtImage";
import Typography from "@material-ui/core/Typography";
import { CurrentAuthMethod } from "../../../constants/AppConstants";
import AuthWrapper from "./AuthWrapper";
import { NavLink } from "react-router-dom";
import NumberFormat from "react-number-format";
import AppTextInput from "@jumbo/components/Common/formElements/AppTextInput";

import Lottie from 'react-lottie';
import animationData from '@lottie/auth/create.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  speed: 1,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

function isValidEmail(email) {
  const emailRegEx = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegEx.test(email);
}

function NumberFormatCustom(props) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      onValueChange={values => {
        onChange(values.formattedValue);
      }}
      format="(##) #####-####"
    />
  );
}

const useStyles = makeStyles(theme => ({
  authThumb: {
    // backgroundColor: fade(theme.palette.secondary.main, 0.12),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    [theme.breakpoints.up("md")]: {
      width: "50%",
      order: 2
    }
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up("md")]: {
      width: props => (props.variant === "default" ? "50%" : "100%"),
      order: 1
    },
    [theme.breakpoints.up("xl")]: {
      padding: 50
    }
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary
  },
  textFieldRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: fade(theme.palette.common.dark, 0.12)
    },
    "& .MuiFormHelperText-root": { color: 'red' }
  },
  textCapital: {
    textTransform: "capitalize"
  },
  textAcc: {
    textAlign: "center",
    "& a": {
      marginLeft: 4
    }
  },
  alrTextRoot: {
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      textAlign: "right"
    }
  }
}));

//variant = 'default', 'standard', 'bgColor'
const SignUp = ({
  method = CurrentAuthMethod,
  variant = "default",
  wrapperVariant = "default"
}) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");

  const [errorEmail, setErrorEmail] = useState("")

  const dispatch = useDispatch();
  const classes = useStyles({ variant });


  const handleChangeEmail = (email) => {
    if (!isValidEmail(email)) {
      setErrorEmail("Digite um E-mail válido!")
      return;
    }

    setErrorEmail("")
  }

  const onSubmit = () => {

    if (!isValidEmail(email)) {
      setErrorEmail("Digite um E-mail válido!")
      return;
    }

    setErrorEmail("")

    dispatch(
      AuhMethods[method].onRegister({
        name,
        email,
        password,
        phone: phone.replace(/[^a-zA-Z0-9]/g, "").replace(/\s/g, "")
      })
    );
  };

  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === "default" ? (
        <Box className={classes.authThumb}>
          <Lottie
            options={defaultOptions}
            height={400}
            width={400}
          />
        </Box>
      ) : null}
      <Box className={classes.authContent}>
        {/*  <Box mb={7}>
          <CmtImage src={"/images/logo.png"} />
        </Box>
      */}
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          <IntlMessages id="appModule.createAccount" />
        </Typography>
        <form>
          <Box mb={2}>
            <TextField
              label={<IntlMessages id="appModule.name" />}
              fullWidth
              onChange={event => setName(event.target.value)}
              defaultValue={name}
              margin="normal"
              variant="outlined"
              required
              className={classes.textFieldRoot}
            />
          </Box>
          <Box mb={2}>
            <TextField
              label={<IntlMessages id="appModule.email" />}
              fullWidth
              onChange={event => setEmail(event.target.value)}
              onBlur={event => handleChangeEmail(event.target.value)}
              defaultValue={email}
              margin="normal"
              variant="outlined"
              required
              helperText={errorEmail}
              className={classes.textFieldRoot}
            />
          </Box>
          <Box mb={2}>
            <AppTextInput
              fullWidth
              variant="outlined"
              margin="normal"
              label="Telefone"
              value={phone}
              required
              onChange={number => setPhone(number)}
              InputProps={{
                inputComponent: NumberFormatCustom
              }}
            />
          </Box>
          <Box mb={2}>
            <TextField
              type="password"
              label={<IntlMessages id="appModule.password" />}
              fullWidth
              onChange={event => setPassword(event.target.value)}
              defaultValue={password}
              margin="normal"
              variant="outlined"
              required
              className={classes.textFieldRoot}
            />
          </Box>

          <Box
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            alignItems={{ sm: "center" }}
            justifyContent={{ sm: "space-between" }}
            mb={10}
          >
              <Button onClick={onSubmit} variant="contained" color="primary" fullWidth>
                <IntlMessages id="appModule.regsiter" />
              </Button>
          </Box>
        </form>

        <Box mb={3}>{dispatch(AuhMethods[method].getSocialMediaIcons())}</Box>

        <Typography className={classes.textAcc}>
          <IntlMessages id="signIn.criar" />
          <NavLink to="/signin">
            <IntlMessages id="appModule.signin" />
          </NavLink>
        </Typography>
        <ContentLoader />
      </Box>
    </AuthWrapper>
  );
};

export default SignUp;
