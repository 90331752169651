import React, {useState} from "react";
import Box from "@material-ui/core/Box";
import {Button} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import CmtAdvCard from "@coremat/CmtAdvCard";
import CmtAdvCardContent from "@coremat/CmtAdvCard/CmtAdvCardContent";
import CmtImage from "@coremat/CmtImage";
import CmtCardHeader from "@coremat/CmtCard/CmtCardHeader";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import EditCreditCard from "./editCreditCard/index";
import {useSelector} from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {NotificationContainer, NotificationManager} from "react-notifications";
import axios from "services/auth/jwt/config";
import dateformat from "dateformat";

const useStyles = makeStyles(theme => ({
  cardRoot: {
    height: "auto"
  },
  subTitleContent: {
    fontSize: 14,
    color: theme.palette.common.dark,
    marginBottom: 18
  },
  planList: {
    padding: 0,
    "& li": {
      padding: 0,
      color: theme.palette.text.secondary,
      "& .MuiTypography-body1": {
        fontSize: 14
      },
      "& .MuiListItemIcon-root": {
        minWidth: 10,
        marginRight: 10
      },
      "& .MuiSvgIcon-root": {
        fontSize: 18
      }
    }
  }
}));

const MySwal = withReactContent(Swal);

const CurrentPlan = ({setNovoPlano, latest_paid, prox_paid}) => {
  const classes = useStyles();
  const [ open, setOpen ] = useState(false);
  const {authUser} = useSelector(({auth}) => auth);
  const {plano} = authUser;

  const token = localStorage.getItem("token");
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  const closeDialog = () => {
    setOpen(false);
  };

  const sweetAlerts = data => {
    MySwal.fire({
      title: "Você tem certeza?",
      text: "Qual o motivo do do seu cancelamento:",
      imageUrl: "https://media.giphy.com/media/BEob5qwFkSJ7G/giphy.gif",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Voltar",
      reverseButtons: true,
      input: "text",
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !MySwal.isLoading(),
      preConfirm: value => {
        if (value) {
          return value;
        } else {
          MySwal.showValidationMessage("Campo Obrigatório!");
        }
      }
    }).then(result => {
      if (result.value) {
        axios
          .post(`plano/suspender`, {motivo_cancelamento: result.value})
          .then(succes => {
            MySwal.fire(
              "Sucesso!",
              "Sucesso ao suspender assinatura!",
              "success"
            ).then(success => (window.location.href = "/app/invoice"));
          })
          .catch(err => {
            NotificationManager.error("Erro ao cancelar assinatura!");
          });
      }
    });
  };

  const getContentTitle = () => (
    <Box display="flex" alignItems="flex-end" mb={5}>
      <Box
        component="span"
        fontSize={{xs: 36, md: 40, xl: 48}}
        fontWeight="fontWeightBold"
        color="common.dark"
        lineHeight="1"
      >
        {plano.valor.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL"
        })}
      </Box>
      <Typography component="span" variant="subtitle1">
        /{plano.intervalo == 1 ? "Mês" : "Trimestral"}
      </Typography>
    </Box>
  );

  return (
    <CmtAdvCard className={classes.cardRoot}>
      <CmtCardHeader
        title="Seu plano Atual"
        titleProps={{
          variant: "h4",
          component: "div"
        }}
      />
      <CmtAdvCardContent
        title={getContentTitle()}
        subTitle={plano.nome}
        subTitleProps={{
          className: classes.subTitleContent
        }}
        extraContent={
          <List component="ul" className={classes.planList}>
            <ListItem>
              <ListItemIcon>
                <NavigateNextIcon />
              </ListItemIcon>
              <ListItemText
                primary={`Max. Atendentes - ${authUser.qtd_atendentes}`}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <NavigateNextIcon />
              </ListItemIcon>
              <ListItemText primary={`Max Robôs - ${authUser.qtd_bot}`} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <NavigateNextIcon />
              </ListItemIcon>
              <ListItemText
                primary={`Max Campanhas - ${authUser.qtd_campanhas}`}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <NavigateNextIcon />
              </ListItemIcon>
              <ListItemText
                primary={`Max Departamentos - ${authUser.qtd_departamentos}`}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <NavigateNextIcon />
              </ListItemIcon>
              <ListItemText
                primary={`Max Whatsapp - ${authUser.qtd_whatsapp}`}
              />
            </ListItem>
          </List>
        }
        reverseDir
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          mt={{sm: -7}}
        >
          <Box mb={{xs: 3, md: 4}}>
            <CmtImage src={"/images/invoice.png"} alt="plan" />
          </Box>
          <Box
            component="p"
            color={authUser.status === "Ativo" ? "success.main" : "error.main"}
            mb={{xs: 3, md: 5}}
          >
            Situação do Plano: <b> {authUser.status} </b>
          </Box>
          <Box component="p" mb={{xs: 3, md: 5}}>
            Última renovação:{" "}
            <b style={{color: "#64ec10"}}>
              {" "}
              {latest_paid ? (
                dateformat(latest_paid.dt_pagamento, "dd/mm/yyyy")
              ) : (
                "-"
              )}{" "}
            </b>
            <br />
            Próxima renovação:{" "}
            <b style={{color: "#ec5310"}}>
              {" "}
              {prox_paid ? authUser.status.includes("Ativo") ? (
                dateformat(prox_paid, "dd/mm/yyyy")
              ) : (
                "-"
              ) : (
                "-"
              )}{" "}
            </b>
          </Box>
          {authUser.is_suspend ? (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setNovoPlano(true)}
            >
              Reativar assinatura
            </Button>
          ) : (
            <React.Fragment>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setNovoPlano(true)}
              >
                Mudar Plano
              </Button>
              <Button
                variant="text"
                style={{marginTop: "10px", fontSize: "10px"}}
                onClick={() => setOpen(true)}
              >
                Alterar cartão de crédito
              </Button>
              <Button
                variant="text"
                style={{margin: "10px", fontSize: "10px"}}
                onClick={() => sweetAlerts()}
              >
                Cancelar Assinatura
              </Button>
            </React.Fragment>
          )}
        </Box>
      </CmtAdvCardContent>
      <EditCreditCard open={open} closeDialog={closeDialog} />
    </CmtAdvCard>
  );
};

export default CurrentPlan;
