import React, {useState, useEffect} from "react";
import PageContainer from "@jumbo/components/PageComponents/layouts/PageContainer";
import IntlMessages from "@jumbo/utils/IntlMessages";
import Fab from "@material-ui/core/Fab";
import makeStyles from "@material-ui/core/styles/makeStyles";
import AddIcon from "@material-ui/icons/Add";
import Skeleton from "@material-ui/lab/Skeleton";
import {Box} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import GridContainer from "@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import CmtList from "@coremat/CmtList";
import {BsPersonPlus} from "react-icons/bs";
import UserSummery from "./UserSumary";
import CreateCamapanha from "./CreateCampanha";
import {NotificationContainer, NotificationManager} from "react-notifications";
import axios from "services/auth/jwt/config";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import CmtCard from "@coremat/CmtCard";
import CmtCardContent from "@coremat/CmtCard/CmtCardContent";
import Alert from "@material-ui/lab/Alert";
import UseSummary2 from "./UserSummary2";
import Logs from "./Logs";

const breadcrumbs = [
  {label: "Home", link: "/"},
  {label: "Atendimento"},
  {label: "Campanhas", isActive: true}
];

const useStyles = makeStyles(theme => ({
  pageFull: {
    width: "100%"
  },
  cardRoot: {
    height: "100%",
    marginBottom: 10
  },
  fab: {
    position: "fixed",
    zIndex: "1000 !important",
    bottom: theme.spacing(8),
    right: theme.spacing(8)
  },
  absolute: {
    position: "absolute",
    bottom: theme.spacing(4),
    right: theme.spacing(6)
  }
}));

const MySwal = withReactContent(Swal);

const Campanha = () => {
  const classes = useStyles();
  const [ loading, setLoading ] = useState(true);
  const [ openCreateDialog, setOpenCreateDialog ] = useState(false);
  const [ openLogsDialog, setOpenLogsDialog ] = useState(false);
  const [ currentContact, setCurrentContact ] = useState();
  const [ setores, setSetores ] = useState();
  const [ userSummary, setUserSummary ] = useState([]);
  const [ update, setUpdate ] = useState();

  const token = localStorage.getItem("token");
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  useEffect(
    () => {
      axios
        .get("campanha/findByUser")
        .then(succes => {
          setUserSummary(succes.data.campanhas);
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
        });
    },
    [ currentContact, update ]
  );

  const sweetAlerts = data => {
    MySwal.fire({
      title: "Você tem certeza?",
      text: "Tem certeza que deseja deletar essa campanha?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Deletar",
      cancelButtonText: "Cancelar",
      reverseButtons: true
    }).then(result => {
      if (result.value) {
        axios
          .delete(`campanha/${data._id}`)
          .then(succes => {
            setUpdate(Math.random());
            NotificationManager.success("Campanha deletada com sucesso!");
          })
          .catch(err => {
            setUpdate(Math.random());
            NotificationManager.error(err.response.data.message);
          });
      }
    });
  };

  const handleOpenDialog = () => {
    setOpenCreateDialog(true);
  };

  const handleSend = data => {
    MySwal.fire({
      title: "Você tem certeza?",
      text: "Tem certeza que deseja enviar essa campanha?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Enviar",
      cancelButtonText: "Cancelar",
      reverseButtons: true
    }).then(result => {
      if (result.value) {
        axios
          .post("campanha/enviar", {campanha: data})
          .then(succes => {
            NotificationManager.info(
              "Sua campanha está sendo enviada! Mantenha seu dispositivo conectado!"
            );
          })
          .catch(error => {
            NotificationManager.error(error.response.data.message);
          });
      }
    });
  };

  const onCloseLogsDialog = () => {
    setOpenLogsDialog(false);
    setCurrentContact(null);
  };

  const handleShowLogs = data => {
    setCurrentContact(data);
    setOpenLogsDialog(true);
  };

  const handleDelete = data => {
    sweetAlerts(data);
  };

  const handleCancel = data => {
    MySwal.fire({
      title: "Você tem certeza?",
      text: "Tem certeza que deseja cancelar o envio dessa campanha?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Cancelar",
      cancelButtonText: "Voltar",
      reverseButtons: true
    }).then(result => {
      if (result.value) {
        axios
          .get(`campanha/cancelar/${data._id}`)
          .then(succes => {
            NotificationManager.success("Envio cancelado com sucesso!");
          })
          .catch(err => {
            NotificationManager.error(err.response.data.message);
          });
      }
    });
  };

  const handleResume = data => {
    axios
      .get(`campanha/iniciar/${data._id}`)
      .then(succes => {
        NotificationManager.info("Enviando mensagens...");
      })
      .catch(err => {
        NotificationManager.error(err.response.data.message);
      });
  };

  const handlePause = data => {
    axios
      .get(`campanha/pausar/${data._id}`)
      .then(succes => {
        NotificationManager.warning("Envio de campanha pausado!");
      })
      .catch(err => {
        NotificationManager.error(err.response.data.message);
      });
  };

  const handleEditar = data => {
    setCurrentContact(data);
    setOpenCreateDialog(true);
  };

  const onCloseComposeDialog = () => {
    setCurrentContact(null);
    setOpenCreateDialog(false);
  };

  return (
    <React.Fragment>
      <PageContainer
        heading={<IntlMessages id="pages.atendimento.campanhas" />}
        breadcrumbs={breadcrumbs}
      >
        <Grid item xs={12}>
          <CmtCard className={classes.cardRoot}>
            <CmtCardContent>
              <Box
                className={classes.root}
                marginTop="10px"
                marginBottom="10px"
              >
                <UseSummary2 />
              </Box>
              <Box
                className={classes.root}
                marginTop="10px"
                marginBottom="10px"
              >
                <Alert severity="info">
                  Como funciona? <br />
                  - Crie uma campanha e escolha para quais contatos ou grupos
                  enviar!<br />
                  - Mantenha seu Whatsapp conectado caso.
                </Alert>
              </Box>
              <Box
                className={classes.root}
                marginTop="10px"
                marginBottom="10px"
              >
                <Alert severity="error" style={{padding: "10px"}}>
                  Atenção:<br />
                  O <b>WhatsApp não permite</b> campanhas de marketing e{" "}
                  <b>spam</b>, caso seja detectado uso abusivo o WhatsApp poderá
                  banir seu número do aplicativo.<br />
                  <b>Não nos responsabilizamos caso isso aconteça.</b>
                </Alert>
              </Box>
            </CmtCardContent>
          </CmtCard>
        </Grid>

        <Box className={classes.pageFull}>
          <GridContainer>
            {loading ? (
              <Grid item xs={12}>
                <Box>
                  <Skeleton variant="rect" height={118} />
                </Box>
              </Grid>
            ) : (
              userSummary.map((row, key) => (
                <Grid item xs={12}>
                  <UserSummery
                    key={key}
                    campanha={row}
                    handleShowLogs={handleShowLogs}
                    handleEditar={handleEditar}
                    handleDelete={handleDelete}
                    handleSend={handleSend}
                    handleCancel={handleCancel}
                    handleResume={handleResume}
                    handlePause={handlePause}
                    setCurrentContact={setCurrentContact}
                  />
                </Grid>
              ))
            )}
          </GridContainer>
        </Box>
        {openCreateDialog && (
          <CreateCamapanha
            userSummary={userSummary}
            setUserSummary={setUserSummary}
            open={openCreateDialog}
            currentCampanha={currentContact}
            handleDialog={onCloseComposeDialog}
            setUpdate={setUpdate}
          />
        )}
        {openLogsDialog && (
          <Logs
            open={openLogsDialog}
            handleDialog={onCloseLogsDialog}
            currentCampanha={currentContact}
          />
        )}
        <Tooltip title="Adicionar" aria-label="add">
          <Fab
            className={classes.fab}
            size="medium"
            color="secondary"
            aria-label="edit"
            onClick={() => {
              handleOpenDialog();
            }}
          >
            <AddIcon />
          </Fab>
        </Tooltip>
      </PageContainer>
    </React.Fragment>
  );
};

export default Campanha;
