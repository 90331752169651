import React, { useState, useEffect } from "react";
import PageContainer from "@jumbo/components/PageComponents/layouts/PageContainer";
import IntlMessages from "@jumbo/utils/IntlMessages";
import GridContainer from "@jumbo/components/GridContainer";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Grid } from "@material-ui/core";
import Paper from '@mui/material/Paper';
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ContentCopyIcon from '@mui/icons-material/CopyAllOutlined';
import { CircularProgress } from "@material-ui/core";
import { NotificationManager } from "react-notifications";
import ApiDisabled from "./Disabled"
import axios from "services/auth/jwt/config";
import Backdrop from "@material-ui/core/Backdrop";
import { Button } from "@material-ui/core";
import LoopIcon from '@mui/icons-material/Loop';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import CounterCard from "./Counter"
import CallMadeIcon from '@mui/icons-material/CallMade';
import ReportIcon from '@mui/icons-material/Report';

const breadcrumbs = [
    { label: "Home", link: "/" },
    { label: "API", isActive: true }
];


const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        textAlign: "left",
        "& > * + *": {
            marginTop: theme.spacing(2)
        }
    },
    paper: {
        padding: theme.spacing(6),
    },
    cardRoot: {
        height: "auto"
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff"
    },
    subTitleContent: {
        fontSize: 14,
        color: theme.palette.common.dark,
        marginBottom: 18
    },
    planList: {
        padding: 0,
        "& li": {
            padding: 0,
            color: theme.palette.text.secondary,
            "& .MuiTypography-body1": {
                fontSize: 14
            },
            "& .MuiListItemIcon-root": {
                minWidth: 10,
                marginRight: 10
            },
            "& .MuiSvgIcon-root": {
                fontSize: 18
            }
        }
    }
}));

const MySwal = withReactContent(Swal);

const Api = ({ }) => {
    const classes = useStyles();

    const [showToken, setShowToken] = useState(false);
    const [loading, setLoading] = useState(true)
    const [token, setToken] = useState("");
    const [isActivated, setIsActivated] = useState(false)

    const tokenAuth = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + tokenAuth;


    const handleClickShowToken = () => setShowToken((show) => !show);

    const handleCopy = async () => {
        await navigator.clipboard.writeText(token);
        NotificationManager.success("Token copiado!")
    }

    const handleNewToken = async () => {
        MySwal.fire({
            title: "Atenção!",
            text: "Esteja ciente de que ao prosseguir, um novo token será gerado automaticamente, tornando o token anterior inativo.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Continuar",
            cancelButtonText: "Voltar",
            reverseButtons: true
        }).then(result => {
            if (result.value) {
                axios.get("/api/token")
                    .then(success => {
                        NotificationManager.success("Novo Token gerado!")
                        setToken(success.data.token)
                    })
                    .catch(error => {
                        NotificationManager.error("Erro ao gerar token!",)
                    })
            }
        });
    }

    const handleActiveApi = () => {
        setLoading(true)
        axios
            .post("/usuario/habilitarApi")
            .then(success => {
                setIsActivated(success.data.isActiveApi)
                setLoading(false)
            })
            .catch(err => err);
    }

    useEffect(() => {
        axios
            .get("/auth/api")
            .then(success => {
                setToken(success.data.tokenApi)
                setIsActivated(success.data.isActiveApi)
                setLoading(false)
            })
            .catch(err => err);
    }, []);

    return (
        <>
            {isActivated ?
                <PageContainer
                    heading={<IntlMessages id="sidebar.Api" />}
                    breadcrumbs={breadcrumbs}
                >
                    <GridContainer style={{ marginBottom: '10px'}}>
                        <Grid item xs={12} md={12}>
                            <Box className={classes.root} marginTop="2px" marginBottom="2px">
                                <Typography variant="h3" component="div">
                                    Amplie a Funcionalidade do Multdesk Conectando-o aos Seus Sistemas
                                </Typography>
                            </Box>
                            <Box className={classes.root} marginTop="2px" marginBottom="2px">
                                <Typography variant="span" component="span" color="textSecondary">
                                    Mantenha seu token em segredo, como faria com sua senha. Não o compartilhe em fóruns, e-mails, mensagens de texto, ou qualquer meio de comunicação que possa ser acessível por terceiros.
                                </Typography>
                            </Box>
                        </Grid>
                    </GridContainer>
                    <Paper elevation={15} className={classes.paper} >
                        <GridContainer>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    id="input-with-icon-textfield"
                                    label="TOKEN"
                                    fullWidth
                                    value={token}
                                    type={showToken ? 'text' : 'password'}
                                    InputProps={{
                                        endAdornment: (
                                            <React.Fragment>
                                                <IconButton
                                                    style={{ marginLeft: '5px' }}
                                                    aria-label="toggle password visibility"
                                                    onClick={handleCopy}
                                                    edge="end"
                                                >
                                                    <ContentCopyIcon />
                                                </IconButton>
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowToken}
                                                    edge="end"
                                                >
                                                    {showToken ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </React.Fragment>
                                        ),
                                    }}
                                    disabled
                                    variant="standard"
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <Button
                                    fullWidth
                                    href="https://multdesk.readme.io"
                                    target="_blank"
                                    variant="text"
                                    color="primary"
                                    startIcon={<OpenInNewIcon />}
                                >
                                    Acessar documentação
                                </Button>
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <Button
                                    fullWidth
                                    variant="text"
                                    color="secondary"
                                    onClick={handleNewToken}
                                    disabled={loading}
                                    startIcon={<LoopIcon />}
                                >
                                    <IntlMessages id="novo.token.api" />
                                </Button>
                            </Grid>
                        </GridContainer>
                    </Paper>
                    <GridContainer style={{ marginTop: '10px' }}>
                        <Grid item xs={12} md={12}>
                            <Box className={classes.root} marginTop="2px" marginBottom="2px">
                                <Typography variant="h4" component="div" color="textSecondary">
                                    Requisiçoes das últimas 24 horas
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <CounterCard label={"Total de Requisiçoes"} counter={100} icon={<CallMadeIcon fontSize="large" />} backgroundColor={["#7DF92D -18.96%", "#4EBD07 108.17%"]} />
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <CounterCard label={"Requisiçoes Com Erro"} counter={20} icon={<ReportIcon fontSize="large" />} backgroundColor={["#FA6262 -18.96%", "#D12323 108.17%"]} />
                        </Grid>
                    </GridContainer>
                </PageContainer>

                :
                <ApiDisabled handleActiveApi={handleActiveApi} />
            }
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}

export default Api