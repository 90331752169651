import React, {useState, useEffect} from "react";
import PageContainer from "@jumbo/components/PageComponents/layouts/PageContainer";
import IntlMessages from "@jumbo/utils/IntlMessages";
import Fab from "@material-ui/core/Fab";
import makeStyles from "@material-ui/core/styles/makeStyles";
import AddIcon from "@material-ui/icons/Add";
import Skeleton from "@material-ui/lab/Skeleton";
import {Box} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import GridContainer from "@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import CmtList from "@coremat/CmtList";
import ItemCell from "./ItemCell";
import {BsPersonPlus} from "react-icons/bs";
import UserSummery from "./UserSumary";
import CreateAtendente from "./CreateAtendente";
import {NotificationContainer, NotificationManager} from "react-notifications";
import axios from "services/auth/jwt/config";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const breadcrumbs = [
  {label: "Home", link: "/"},
  {label: "Atendimento"},
  {label: "Atendente", isActive: true}
];

const useStyles = makeStyles(theme => ({
  pageFull: {
    width: "100%"
  },
  cardRoot: {
    height: "100%",
    marginBottom: 10
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(8),
    right: theme.spacing(8)
  },
  absolute: {
    position: "absolute",
    bottom: theme.spacing(4),
    right: theme.spacing(6)
  }
}));

const MySwal = withReactContent(Swal);

const Atendente = () => {
  const classes = useStyles();
  const [ loading, setLoading ] = useState(true);
  const [ qtdSetor, setQtdSetor ] = useState();
  const [ openCreateDialog, setOpenCreateDialog ] = useState(false);
  const [ currentContact, setCurrentContact ] = useState();
  const [ setores, setSetores ] = useState();
  const [ userSummary, setUserSummary ] = useState([]);
  const [ update, setUpdate ] = useState();

  const token = localStorage.getItem("token");
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  const notifications = [
    {
      id: 2,
      bgColor: "#90e0ef",
      color: "#0077b6",
      content:
        "Não há Atendentes cadastrados, para começar adicione um novo Atendente!",
      showIcon: false,
      icon: <BsPersonPlus />
    }
  ];

  useEffect(
    () => {
      axios
        .get("atendimento/findAtendenteByUser")
        .then(succes => {
          setQtdSetor(succes.data.atendentes.length);
          setUserSummary(succes.data.atendentes);
          setLoading(false);
        })
        .catch(error => error);
    },
    [ update ]
  );

  const handleOpenDialog = () => {
    setOpenCreateDialog(true);
  };

  const sweetAlerts = data => {
    MySwal.fire({
      title: "Você tem certeza?",
      text: "Tem certeza que deseja deletar esse  departamento?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Deletar!",
      cancelButtonText: "Cancelar!",
      reverseButtons: true
    }).then(result => {
      if (result.value) {
        axios
          .delete(`atendimento/atendente/${data._id}`)
          .then(succes => {
            setUpdate(Math.random());
            if(succes.data.isExist){
              NotificationManager.warning(succes.data.message, succes.data.title);
            }else{
              NotificationManager.success(succes.data.message);
            }
          })
          .catch(err => {
            setUpdate(Math.random());
            NotificationManager.success("Erro ao deletar atendente!");
          });
      }
    });
  };

  const handleDelete = data => {
    sweetAlerts(data);
  };

  const handleEditar = data => {
    setCurrentContact(data);
    setOpenCreateDialog(true);
  };

  const onCloseComposeDialog = () => {
    setCurrentContact(null);
    setOpenCreateDialog(false);
  };

  return (
    <React.Fragment>
      <PageContainer
        heading={<IntlMessages id="pages.atendimento.atendente" />}
        breadcrumbs={breadcrumbs}
      >
        <Box className={classes.pageFull}>
          <GridContainer>
            {loading ? (
              <Grid item xs={12}>
                <Box>
                  <Skeleton variant="rect" height={118} />
                </Box>
              </Grid>
            ) : qtdSetor === 0 ? (
              <CmtList
                data={notifications}
                renderRow={(item, index) => (
                  <ItemCell item={item} key={index} />
                )}
              />
            ) : (
              userSummary.map((row, key) => (
                <Grid item xs={12}>
                  <UserSummery
                    userSummary={row}
                    key={key}
                    handleEditar={handleEditar}
                    handleDelete={handleDelete}
                  />
                </Grid>
              ))
            )}
          </GridContainer>
        </Box>
        {openCreateDialog && (
          <CreateAtendente
            userSummary={userSummary}
            setQtdSetor={setQtdSetor}
            setUserSummary={setUserSummary}
            open={openCreateDialog}
            currentContact={currentContact}
            handleDialog={onCloseComposeDialog}
            setUpdate={setUpdate}
          />
        )}
      </PageContainer>
      <Tooltip title="Adicionar" aria-label="add">
        <Fab
          className={classes.fab}
          color="secondary"
          aria-label="edit"
          onClick={() => {
            handleOpenDialog();
          }}
        >
          <AddIcon />
        </Fab>
      </Tooltip>
    </React.Fragment>
  );
};

export default Atendente;
