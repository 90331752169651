import React, {useState, useEffect} from "react";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import GridContainer from "@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import Loader from "react-loaders";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import axios from "services/auth/jwt/config";
import Slide from "@material-ui/core/Slide";
import DiagramaEtapa from "./DiagramaEtapa";

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: "relative"
  },
  formControl: {
    margin: theme.spacing(2)
  },
  dialogTitleRoot: {
    "& .MuiTypography-h6": {
      fontSize: 18,
      color: theme.palette.common.dark
    }
  }
}));

const Transition = React.forwardRef(function Transition(props, ref){
  return <Slide direction="up" ref={ref} {...props} />;
});

const CriarEtapas = ({open, handleDialog, bot}) => {
  const classes = useStyles();
  const token = localStorage.getItem("token");
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  return (
    <Dialog
      open={open}
      onClose={handleDialog}
      fullScreen
      className={classes.dialogRoot}
      TransitionComponent={Transition}
    >
      <DialogTitle className={classes.dialogTitleRoot}>
        {"Editar Etapas do Bot"}
      </DialogTitle>
      <DialogContent dividers>
        <Grid item xs={12}>
          <DiagramaEtapa bot={bot} handleDialog={handleDialog} />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CriarEtapas;
