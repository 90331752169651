import React, { useEffect, useLayoutEffect, useState } from "react";
import CmtImage from "@coremat/CmtImage";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import { fade } from "@material-ui/core";
import { IconsImages } from "./Icons";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { FaDownload } from "react-icons/fa";
import { IoCopy } from "react-icons/io5";
import { MdDelete, MdTouchApp } from "react-icons/md";
import { URL_STORAGE } from "util/socket";

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    borderRadius: 6,
    overflow: "hidden",
    "&:hover": {
      "& $titleBar": {
        bottom: 0
      }
    }
  },
  titleBar: {
    position: "absolute",
    color: theme.palette.common.white,
    backgroundColor: fade(theme.palette.common.black, 0.8),
    bottom: "-100px",
    left: 0,
    right: 0,
    zIndex: 1,
    padding: "10px 16px",
    transition: "all 0.3s ease"
  },
  titleRoot: {
    fontSize: 10,
    width: "100%"
  },
  subTitleRoot: {
    fontSize: 9,
    color: fade(theme.palette.common.white, 0.38)
  }
}));

const PictureItem = ({
  item,
  handleDelete,
  handleCopy,
  selectedFile,
  isDialog
}) => {
  const classes = useStyles();
  const boxRef = React.createRef();
  const [boxHeight, setBoxHeight] = useState(0);
  const [dimensions, setDimensions] = React.useState({
    height: 0,
    width: 0
  });

  const calculateHeight = () => {
    if (boxRef.current) {
      setBoxHeight(boxRef.current.clientWidth);
    }
  };

  useEffect(
    () => {
      calculateHeight();
    },
    [item, dimensions]
  );

  useLayoutEffect(() => {
    function windowResized() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
    }

    window.addEventListener("resize", windowResized);
    windowResized();
    return () => window.removeEventListener("resize", windowResized);
  }, []);

  const getIconFile = (name, link) => {
    var mimetype = name.split(".");
    mimetype = mimetype[mimetype.length - 1];

    if (
      mimetype.includes("jpeg") ||
      mimetype.includes("jpg") ||
      mimetype.includes("png")
    )
      return URL_STORAGE + "/" + link;

    return IconsImages[mimetype] || "/images/files/file.png";
  };

  return (
    <React.Fragment>
      <Box
        className={classes.root}
        ref={boxRef}
        style={{ height: "100px", width: "100px", flexBasis: "10%" }}
      >
        <CmtImage
          key={200}
          src={getIconFile(item.nome, item.link)}
          style={{ height: "100px", width: "100px", objectFit: "cover" }}
        />
        <Box className={classes.titleBar}>
          <Typography
            component="div"
            variant="h4"
            className={classes.titleRoot}
          >
            {item.nome}
          </Typography>
          <Typography className={classes.subTitleRoot}>
            {(item.size / (1024 * 1024)).toFixed(2)} Mb
          </Typography>
        </Box>
      </Box>
      <Box>
        {isDialog ? (
          <Tooltip title="Selecionar">
            <IconButton aria-label="delete" onClick={() => selectedFile(item)}>
              <MdTouchApp size="15px" />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Copiar link">
            <IconButton aria-label="delete" onClick={() => handleCopy(item)}>
              <IoCopy size="15px" />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title="Download">
          <IconButton
            aria-label="delete"
            href={URL_STORAGE + "/" + item.link}
            target="_blank"
          >
            <FaDownload size="15px" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Excluir">
          <IconButton
            aria-label="delete"
            onClick={() => {
              handleDelete(item);
            }}
          >
            <MdDelete size="15px" />
          </IconButton>
        </Tooltip>
      </Box>
    </React.Fragment>
  );
};

export default PictureItem;
