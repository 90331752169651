import React, {useContext, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import useStyles from "../index.style";
import Box from "@material-ui/core/Box";
import ListItem from "@material-ui/core/ListItem";
import CmtList from "@coremat/CmtList";
import List from "@material-ui/core/List";
import PropTypes from "prop-types";
import {
  getContactCounts,
  getLabelsList,
  setFilterType
} from "redux/actions/ContactApp";
import ItemCell from "./ItemCell";
import AddLabel from "./AddLabel";
import PerfectScrollbar from "react-perfect-scrollbar";
import {withWidth} from "@material-ui/core";
import {getAppSidebarHeight} from "@jumbo/constants/AppConstants";
import MoreOptions from "./MoreOptions";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import AppContext from "@jumbo/components/contextProvider/AppContextProvider/AppContext";
import LabelCell from "./LabelCell";
import Fab from "@material-ui/core/Fab";
import Tooltip from "@material-ui/core/Tooltip";
import GroupIcon from "@material-ui/icons/Group";
import StarIcon from "@material-ui/icons/Star";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import DeleteIcon from "@material-ui/icons/Delete";

const foldersList = [
  {id: 1, name: "Contatos", slug: "contacts", icon: <GroupIcon />},
  {id: 2, name: "Favoritos", slug: "starred", icon: <StarIcon />},
  {id: 6, name: "Lixeira", slug: "trash", icon: <DeleteIcon />}
];

const Sidebar = ({onClickCreateContact, width}) => {
  const {
    isSideBarCollapsed,
    labelsList,
    filterType,
    contactsList
  } = useSelector(({contactApp}) => contactApp);
  const {showFooter} = useContext(AppContext);
  const dispatch = useDispatch();

  useEffect(
    () => {
      dispatch(getContactCounts());
    },
    [ dispatch, contactsList ]
  );

  useEffect(
    () => {
      dispatch(getLabelsList());
    },
    [ dispatch ]
  );

  const onChangeFolder = folder => {
    dispatch(
      setFilterType({
        selectedFolder: folder,
        selectedLabel: "",
        searchText: "",
        page: 1
      })
    );
  };

  const onChangeLabel = label => {
    dispatch(
      setFilterType({
        selectedFolder: "",
        selectedLabel: label,
        searchText: "",
        page: 1
      })
    );
  };

  const classes = useStyles({
    isCollapsed: isSideBarCollapsed,
    height: getAppSidebarHeight(width, showFooter)
  });

  return (
    <Box className={classes.inBuildAppSidebar}>
      <Box className={classes.inBuildAppSidebarHeader} />

      <Tooltip title="Adicionar" aria-label="add">
        <Fab
          className={classes.fab}
          color="secondary"
          aria-label="Adicionar"
          onClick={onClickCreateContact}
        >
          <PersonAddIcon />
        </Fab>
      </Tooltip>

      <PerfectScrollbar className={classes.perfectScrollbarContactSidebar}>
        <List component="nav" className={classes.appNav}>
          <CmtList
            data={foldersList}
            renderRow={(item, index) => (
              <ItemCell
                key={index}
                item={item}
                classes={classes}
                selectedItem={filterType.selectedFolder}
                onChange={onChangeFolder}
              />
            )}
          />

          <ListItem component="div" className={classes.appNavHeaderItem}>
            <Box component="span" className={classes.appNavHeaderItemText}>
              Grupos
            </Box>
          </ListItem>

          <CmtList
            data={labelsList}
            renderRow={(item, index) => (
              <LabelCell
                key={index}
                item={item}
                classes={classes}
                selectedItem={filterType.selectedLabel}
                onChange={onChangeLabel}
              />
            )}
          />

          <AddLabel />

          <ListItem component="div" className={classes.appNavHeaderItem}>
            <Box component="span" className={classes.appNavHeaderItemText}>
              Opções
            </Box>
          </ListItem>
          {<MoreOptions classes={classes} contactsList={contactsList} />}
        </List>
      </PerfectScrollbar>
    </Box>
  );
};

export default withWidth()(Sidebar);

Sidebar.prototype = {
  onClickCreateContact: PropTypes.func
};
