import React from "react";
import {Box, fade} from "@material-ui/core";
import CmtCard from "../../../@coremat/CmtCard";
import CmtCardHeader from "../../../@coremat/CmtCard/CmtCardHeader";
import CmtCardContent from "../../../@coremat/CmtCard/CmtCardContent";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import clsx from "clsx";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import LocalPhoneIcon from "@material-ui/icons/LocalPhone";
import blue from "@material-ui/core/colors/blue";
import {GrStatusInfo} from "react-icons/gr";
import {MdPayment} from "react-icons/md";
import {FaBirthdayCake} from "react-icons/fa";

const useStyles = makeStyles(theme => ({
  iconView: {
    backgroundColor: fade(blue["500"], 0.1),
    color: blue["500"],
    padding: 8,
    borderRadius: 4,
    "& .MuiSvgIcon-root": {
      display: "block"
    },
    "&.web": {
      backgroundColor: fade(theme.palette.warning.main, 0.1),
      color: theme.palette.warning.main
    },
    "&.phone": {
      backgroundColor: fade(theme.palette.success.main, 0.15),
      color: theme.palette.success.dark
    }
  },
  wordAddress: {
    wordBreak: "break-all",
    cursor: "pointer"
  }
}));

const formatDate = date => {
  const year = new Intl.DateTimeFormat("en", {year: "numeric"}).format(date);
  const month = new Intl.DateTimeFormat("en", {month: "2-digit"}).format(date);
  const day = new Intl.DateTimeFormat("en", {day: "2-digit"}).format(date);

  return `${day}-${month}-${year}`;
};

const Contact = ({userDetail}) => {
  const {email, phone, status, plano, dataNascimento} = userDetail;
  const classes = useStyles();

  return (
    <React.Fragment>
      <CmtCard>
        <CmtCardHeader title="Contato" />
        <CmtCardContent>
          <Box display="flex" alignItems="center" mb={{xs: 4, sm: 6}}>
            <Box className={classes.iconView}>
              <MailOutlineIcon />
            </Box>
            <Box ml={5}>
              <Box component="span" fontSize={12} color="text.secondary">
                Email
              </Box>
              <Box component="p" className={classes.wordAddress} fontSize={16}>
                <Box component="a" href={`mailto:${email}`}>
                  {email}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" mb={{xs: 4, sm: 6}}>
            <Box className={classes.iconView}>
              <FaBirthdayCake />
            </Box>
            <Box ml={5}>
              <Box component="span" fontSize={12} color="text.secondary">
                Data Nascimento
              </Box>
              <Box component="p" className={classes.wordAddress} fontSize={16}>
                <Box component="a">
                  {dataNascimento && formatDate(new Date(dataNascimento))}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" mb={{xs: 4, sm: 6}}>
            <Box className={classes.iconView}>
              <GrStatusInfo />
            </Box>
            <Box ml={5}>
              <Box component="span" fontSize={12} color="text.secondary">
                Status
              </Box>
              <Box component="p" className={classes.wordAddress} fontSize={16}>
                <Box component="a">{status}</Box>
              </Box>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" mb={{xs: 4, sm: 6}}>
            <Box className={classes.iconView}>
              <MdPayment />
            </Box>
            <Box ml={5}>
              <Box component="span" fontSize={12} color="text.secondary">
                Plano
              </Box>
              <Box component="p" className={classes.wordAddress} fontSize={16}>
                <Box component="a">{plano.nome}</Box>
              </Box>
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Box className={clsx(classes.iconView, "phone")}>
              <LocalPhoneIcon />
            </Box>
            <Box ml={5}>
              <Box component="span" fontSize={12} color="text.secondary">
                Telefone
              </Box>
              <Box
                component="p"
                className={classes.wordAddress}
                fontSize={16}
                color="text.primary"
              >
                {phone}
              </Box>
            </Box>
          </Box>
        </CmtCardContent>
      </CmtCard>
    </React.Fragment>
  );
};

export default Contact;

Contact.prototype = {
  userDetail: PropTypes.object.isRequired
};
