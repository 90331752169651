import * as React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

function SimpleDialog(props) {
  const { onClose, selectedValue, open, sistemas } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="xs">
      <DialogTitle>Sistemas</DialogTitle>
      <List sx={{ pt: 0 }}>
        {sistemas.map((sistema) => (
          <ListItem disableGutters key={sistema.id}>
            <ListItemButton onClick={() => handleListItemClick(sistema.label)}>
              <ListItemAvatar>
                <Avatar src={sistema.imgIcon} />
              </ListItemAvatar>
              <ListItemText primary={sistema.value} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  sistemas: PropTypes.array
};

export default SimpleDialog