import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { Switch } from 'react-router-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import configureStore, { history } from './redux/store';
import AppWrapper from './@jumbo/components/AppWrapper';
import AppContextProvider from './@jumbo/components/contextProvider/AppContextProvider';
import Routes from './routes';
import config from "./config"
import { Helmet } from 'react-helmet';
import { NotificationContainer } from "react-notifications";
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'

export const store = configureStore();
export const queryClient = new QueryClient()
const captlize = (string) => {
  return string.charAt(0).toUpperCase() + string.substr(1);
}

const App = () => (
  <AppContextProvider>
    <Helmet>
      <title> {captlize(config.EMPRESA)} - Admin</title>
    </Helmet>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <QueryClientProvider client={queryClient}>
          <AppWrapper>
            <Switch>
              <Routes />
            </Switch>
            <NotificationContainer />
          </AppWrapper>
        </QueryClientProvider>
      </ConnectedRouter>
    </Provider>
  </AppContextProvider>
);

export default App;
