import React, { useState, useEffect } from "react";
import IntlMessages from "@jumbo/utils/IntlMessages";
import PageContainer from "@jumbo/components/PageComponents/layouts/PageContainer";
import GridContainer from "@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { Typography } from "@material-ui/core";
import CmtCard from "@coremat/CmtCard";
import CmtCardContent from "@coremat/CmtCard/CmtCardContent";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";

import {
    DataGrid,
    ptBR,
    GridOverlay,
} from "@material-ui/data-grid";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import axios from "services/auth/jwt/config";

const breadcrumbs = [
    { label: "Home", link: "/" },
    { label: "Avaliações", isActive: true }
];

const useStyles = makeStyles(theme => ({
    cardRoot: {
        height: "100%",
        margin: '10px'
    },
    fab: {
        position: "fixed",
        bottom: theme.spacing(8),
        right: theme.spacing(8)
    },
    root: {
        width: "100%",
        textAlign: "center",
        "& > * + *": {
            marginTop: theme.spacing(2)
        }
    }
}));

const theme = createMuiTheme(ptBR);

const CustomLoadingOverlay = () => {
    return (
        <GridOverlay>
            <div style={{ position: "absolute", top: 0, width: "100%" }}>
                <LinearProgress />
            </div>
        </GridOverlay>
    );
};

const columns = [
    { field: "id", headerName: "id", width: 180 },
    { field: "name", headerName: "Nome", width: 280 },
    { field: "category", headerName: "Categoria", width: 220 },
    { field: "language", headerName: "Linguagem", width: 180 },
    { field: "status", headerName: "Status", width: 180 },
];

const TemplateMessage = ({ sessao, handleClearSessao }) => {

    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    useEffect(
        () => {
            axios
                .get("whatsapp-oficial/templates/" + sessao._id._id)
                .then(success => {
                    console.log(success.data.data)
                    setData(success.data.data);
                    setLoading(false);
                })
                .catch(error => {
                    setLoading(false);
                });
        },
        []
    );

    return (
        <PageContainer
            heading={<IntlMessages id="sidebar.whatsapp.template_messages" />}
            breadcrumbs={breadcrumbs}
        >
            <GridContainer>
                <Grid item xs={12}>
                    <CmtCard className={classes.cardRoot}>
                        <CmtCardContent>
                            <Grid item xs={12}>
                                <Box className={classes.root} marginTop="15px" marginBottom="15px">
                                    <Typography variant="h3" component="h3">
                                        Modelos de mensagem
                                    </Typography>
                                </Box>
                                <Box className={classes.root} marginTop="15px" marginBottom="15px">
                                    <Typography variant="span" component="span">
                                        É preciso ter um modelo de mensagem para começar uma conversa iniciada pela empresa. As conversas podem incluir mensagens de atendimento ao cliente, lembretes sobre horas marcadas, atualizações de pagamento ou envio, alertas e muito mais.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Box className={classes.root} marginTop="15px" marginBottom="15px">
                                <Alert severity="info">
                                    Saiba mais em: <a href="https://developers.facebook.com/docs/whatsapp/api/messages/message-templates/">Facebook - Modelos de mensagem</a>
                                </Alert>
                            </Box>
                            <ThemeProvider theme={theme}>
                                <DataGrid
                                    rows={data}
                                    columns={columns}
                                    autoHeight
                                    isRowSelectable={false}
                                    pageSize={15}
                                    density="compact"
                                    loading={loading}
                                    components={{
                                        LoadingOverlay: CustomLoadingOverlay
                                    }}
                                />
                            </ThemeProvider>
                            <Grid item xs={12}>
                                <Box marginTop="15px" marginBottom="15px">
                                    <Button variant="contained" color="primary" onClick={handleClearSessao}>Voltar</Button>
                                </Box>
                            </Grid>
                        </CmtCardContent>
                    </CmtCard>
                </Grid>
            </GridContainer>
        </PageContainer>
    );
};

export default TemplateMessage;
