import React, {useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import GridContainer from "@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import AppTextInput from "@jumbo/components/Common/formElements/AppTextInput";
import Button from "@material-ui/core/Button";
import {requiredMessage} from "@jumbo/constants/ErrorMessages";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {NotificationManager} from "react-notifications";
import uuid from "react-uuid";
import axios from "services/auth/jwt/config";
import Loader from "react-loaders";
import Switch from "@material-ui/core/Switch";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: "relative"
  },
  dialogTitleRoot: {
    "& .MuiTypography-h6": {
      fontSize: 18,
      color: theme.palette.common.dark
    }
  }
}));

const CreateSetor = ({
  open,
  handleDialog,
  currentContact,
  userSummary,
  setUserSummary,
  setQtdSetor,
  setUpdate
}) => {
  const classes = useStyles();

  const [ fname, setFname ] = useState(
    currentContact ? currentContact.nome : ""
  );
  const [ segmento, setSegmento ] = useState(
    currentContact ? currentContact.segmento : ""
  );
  const [ responsavel, setResponsavel ] = useState(
    currentContact ? currentContact.responsavel : ""
  );

  const [ isShowBot, setIsShowBot ] = useState(
    currentContact ? currentContact.isShowBot : true
  );

  const [ isActive, setisActive ] = useState(
    currentContact ? currentContact.isActive : true
  );

  const [ fnameError, setFnameError ] = useState("");
  const [ segmentoError, setSegmentoError ] = useState("");
  const [ responsavelError, setResponsavelError ] = useState("");
  const [ loader, setLoader ] = useState(false);

  const token = localStorage.getItem("token");
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  const checkValidations = () => {
    if (!fname) {
      setFnameError(requiredMessage);
    } else if (!segmento) {
      setSegmentoError(requiredMessage);
    } else if (!responsavel) {
      setResponsavelError(requiredMessage);
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    setLoader(true);

    if (currentContact) {
      const data = {
        _id: currentContact._id,
        nome: fname,
        segmento,
        responsavel,
        isShowBot,
        isActive
      };

      axios
        .post("atendimento/departamento/update", {
          departamento: data
        })
        .then(succes => {
          setUpdate(Math.random());
          handleDialog();
          setLoader(false);
          NotificationManager.success("Departamento salvo com sucesso!");
        })
        .catch(error => {
          setUpdate(Math.random());
          handleDialog();
          setLoader(false);
          NotificationManager.error(error.response.data.message);
        });
    } else {
      const data = {
        _id: uuid(),
        nome: fname,
        segmento,
        responsavel,
        isShowBot,
        isActive
      };

      axios
        .post("atendimento/departamento", {
          departamento: data
        })
        .then(succes => {
          setUpdate(Math.random());
          handleDialog();
          setLoader(false);
          NotificationManager.success("Departamento salvo com sucesso!");
        })
        .catch(error => {
          setLoader(false);
          handleDialog();
          NotificationManager.error(error.response.data.message);
        });
    }
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleDialog}
      className={classes.dialogRoot}
    >
      <DialogTitle className={classes.dialogTitleRoot}>
        {currentContact ? "Editar Departamento" : "Criar Departamento"}
      </DialogTitle>
      <DialogContent dividers>
        <Box
          display="flex"
          flexDirection={{xs: "column", md: "row"}}
          alignItems="center"
          mb={{xs: 6, md: 5}}
        >
          <GridContainer>
            <Grid item xs={12}>
              <AppTextInput
                fullWidth
                variant="outlined"
                label="Nome"
                value={fname}
                onChange={e => {
                  setFname(e.target.value);
                  setFnameError("");
                }}
                helperText={fnameError}
              />
            </Grid>
          </GridContainer>
        </Box>
        <Box
          display="flex"
          flexDirection={{xs: "column", md: "row"}}
          alignItems="center"
          mb={{xs: 6, md: 5}}
        >
          <GridContainer>
            <Grid item xs={12}>
              <AppTextInput
                fullWidth
                variant="outlined"
                label="Segmento"
                value={segmento}
                onChange={e => {
                  setSegmento(e.target.value);
                  setSegmentoError("");
                }}
                helperText={segmentoError}
              />
            </Grid>
          </GridContainer>
        </Box>
        <Box
          display="flex"
          flexDirection={{xs: "column", md: "row"}}
          alignItems="center"
          mb={{xs: 6, md: 5}}
        >
          <GridContainer>
            <Grid item xs={12}>
              <AppTextInput
                fullWidth
                variant="outlined"
                label="Responsável"
                value={responsavel}
                onChange={e => {
                  setResponsavel(e.target.value);
                  setResponsavelError("");
                }}
                helperText={responsavelError}
              />
            </Grid>
          </GridContainer>
        </Box>

        <Box
          display="flex"
          flexDirection={{xs: "column", md: "row"}}
          alignItems="center"
          mb={{xs: 6, md: 5}}
        >
          <GridContainer>
            <Grid item xs={12}>
              <InputLabel id="demo-simple-select-label">
                Ativo:
                <Switch
                  checked={isActive}
                  onChange={() => setisActive(!isActive)}
                />
              </InputLabel>
            </Grid>
          </GridContainer>
        </Box>

        <Box
          display="flex"
          flexDirection={{xs: "column", md: "row"}}
          alignItems="center"
          mb={{xs: 6, md: 5}}
        >
          <GridContainer>
            <Grid item xs={12}>
              <InputLabel id="demo-simple-select-label">
                Listar departamento no bot:
                <Switch
                  checked={isShowBot}
                  onChange={() => setIsShowBot(!isShowBot)}
                />
              </InputLabel>
            </Grid>
          </GridContainer>
        </Box>

        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button onClick={handleDialog}>Cancelar</Button>
          <Box ml={2}>
            {loader ? (
              <Loader type="ball-pulse-sync" />
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={checkValidations}
              >
                Salvar
              </Button>
            )}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CreateSetor;

CreateSetor.prototype = {
  open: PropTypes.bool.isRequired,
  handleDialog: PropTypes.func,
  selectedContact: PropTypes.object
};

CreateSetor.defaultProps = {
  selectedContact: null
};
