import { useQuery } from 'react-query';
import axios from "services/auth/jwt/config";

const getSessao = async (ctx) => {
    try {
        const [, departamento_id] = ctx.queryKey;
        if (!departamento_id) return false
        const { data } = await axios.get(`/whatsapp/session/${departamento_id}`)
        return data
    } catch (e) {
        return false
    }
}

export default function useFetchGetSolicitacoes(departamento_id) {
    console.log(`Buscando sessao: ${departamento_id}`)
    return useQuery(['whatsappSession', departamento_id], getSessao, {
        refetchOnWindowFocus: false,
        refetchInterval: 10000,
        enabled: !!departamento_id,
        initialData: {}
    });
}