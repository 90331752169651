import React, {useEffect, useState} from "react";
import Box from "@material-ui/core/Box";
import Header from "./Header";
import GridContainer from "../../../@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import Contact from "./Contact";
import {useDispatch, useSelector} from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import EditIcon from "@material-ui/icons/Edit";
import Fab from "@material-ui/core/Fab";
import NumberFormat from "react-number-format";
import Modal from "@material-ui/core/Modal";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import {KeyboardDatePicker} from "@material-ui/pickers";
import SweetAlert from "react-bootstrap-sweetalert";
import Button from "@material-ui/core/Button";
import IntlMessages from "../../../@jumbo/utils/IntlMessages";
import {CircularProgress} from "@material-ui/core";
import {fetchError, fetchStart, fetchSuccess} from "../../../redux/actions";
import axios from "../../../services/auth/jwt/config";
import {setAuthUser, updateLoadUser} from "../../../redux/actions/Auth";
import {NotificationContainer} from "react-notifications";

const useStyles = makeStyles(theme => ({
  pageFull: {
    width: "100%"
  },
  profileSidebar: {
    "@media screen and (min-width: 1280px) and (max-width: 1499px)": {
      flexBasis: "100%",
      maxWidth: "100%"
    }
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(8),
    right: theme.spacing(8)
  },
  profileMainContent: {
    "@media screen and (min-width: 1280px) and (max-width: 1499px)": {
      flexBasis: "100%",
      maxWidth: "100%"
    }
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 8, 6)
  }
}));

class NumberFormatCustom extends React.Component {
  render() {
    return (
      <NumberFormat
        class=".MuiInputBase-input .MuiInput-input"
        {...this.props}
        onValueChange={values => {
          this.props.onChange({
            target: {
              value: values.value
            }
          });
        }}
        fullWidth
        marginDense
        format="(##) #####-####"
        placeholder="(00) 00000-0000"
      />
    );
  }
}

function rand(){
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle(){
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const Profile = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [ tabValue, setTabValue ] = useState("about");
  const {authUser} = useSelector(({auth}) => auth);

  const [ modalStyle ] = useState(getModalStyle);
  const [ open, setOpen ] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const [ success, setSuccess ] = useState(false);
  const [ warning, setWarning ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const {email, phone, dataNascimento, nome, status, plano} = authUser;

  const [ dataNascimentoState, setDataNascimento ] = useState(
    new Date(dataNascimento)
  );
  const [ telefoneState, setTelefone ] = useState(phone);
  const [ requiredTelefone, setRequiredTelefone ] = useState(false);

  const onClose = () => {
    setOpen(false);
  };

  const onCancel = () => {
    setSuccess(false);
    setWarning(false);
    setOpen(false);
  };

  const onEditar = (loaded = false) => {
    if (checkRequired()) return false;
    setLoading(true);

    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    axios
      .put("usuario", {
        email: email,
        nome: nome,
        phone: telefoneState,
        dataNascimento: dataNascimentoState
      })
      .then(({data}) => {
        if (!data.error) {
          dispatch(
            setAuthUser({
              email: email,
              nome: nome,
              phone: telefoneState,
              status: status,
              plano: plano.nome,
              dataNascimento: dataNascimentoState
            })
          );
          setLoading(false);
          setSuccess(true);
        } else {
          setLoading(false);
          setWarning(true);
        }
      })
      .catch(function(error){
        setLoading(false);
        setWarning(true);
      });
  };

  const checkRequired = () => {
    let isEmpty = false;
    if (telefoneState === "") {
      isEmpty = true;
      setRequiredTelefone(true);
    }

    return isEmpty;
  };

  return (
    <React.Fragment>
      {authUser && (
        <Box className={classes.pageFull}>
          <Header
            classes={classes}
            userDetail={authUser}
            tabValue={tabValue}
            handleTabChange={handleTabChange}
          />
          <GridContainer>
            <Grid item xs={12} lg={4} className={classes.profileSidebar}>
              <Box mb={6}>
                <Contact userDetail={authUser} />
              </Box>
            </Grid>
          </GridContainer>

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div style={modalStyle} className={classes.paper}>
              <h2 id="simple-modal-title">{authUser.nome}</h2>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <div key="basic_day" className="picker">
                      <KeyboardDatePicker
                        autoOk
                        variant="inline"
                        inputVariant="standard"
                        label="Data de Nascimento"
                        format="DD/MM/YYYY"
                        value={dataNascimentoState}
                        InputAdornmentProps={{position: "end"}}
                        onChange={setDataNascimento}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <FormControl margin="normal">
                    <InputLabel htmlFor="name-input">Telefone</InputLabel>
                    <Input
                      id="telefone"
                      value={telefoneState}
                      margin="normal"
                      onChange={event => setTelefone(event.target.value)}
                      type="tel"
                      inputComponent={NumberFormatCustom}
                      error={requiredTelefone}
                      fullWidth
                    />
                  </FormControl>
                </div>
              </div>
              <div className="modal-box-footer">
                <Button className="text-primary float-left" onClick={onClose}>
                  <i className="zmdi zmdi-close mr-2" />Cancelar
                </Button>
                {loading ? (
                  <CircularProgress className="float-right" />
                ) : (
                  <Button
                    className="float-right"
                    variant="contained"
                    color="primary"
                    onClick={() => onEditar()}
                  >
                    <i className="zmdi zmdi-mail-send mr-2" />Salvar
                  </Button>
                )}
              </div>
            </div>
          </Modal>
          <SweetAlert
            show={success}
            success
            title={<IntlMessages id="sweetAlerts.success" />}
            onConfirm={onCancel}
            style={{zIndex: "1", position: "fixed"}}
          >
            Perfil alterado com sucesso!
          </SweetAlert>
          <SweetAlert
            show={warning}
            style={{zIndex: "1", position: "fixed"}}
            error
            confirmBtnText={<IntlMessages id="button.cancelar" />}
            confirmBtnBsStyle="danger"
            title={<IntlMessages id="error.request" />}
            onConfirm={onCancel}
          >{`Erro ao atualizar usuário!`}</SweetAlert>
        </Box>
      )}
      <Fab
        className={classes.fab}
        color="secondary"
        aria-label="edit"
        onClick={handleOpen}
      >
        <EditIcon />
      </Fab>
    </React.Fragment>
  );
};

export default Profile;
