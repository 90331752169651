import React from "react";
import CounterCard from "@jumbo/components/Common/CounterCard";

const ProjectCounterCard = ({ counter, label, icon, backgroundColor }) => {
  return (
    <CounterCard
      icon={icon}
      number={counter}
      label={label}
      labelProps={{
        fontSize: 14
      }}
      backgroundColor={backgroundColor}
      gradientDirection="180deg"
    />
  );
};

export default ProjectCounterCard;
