import React, { useEffect, useState } from "react";
import Diagram, { createSchema, useSchema } from "beautiful-react-diagrams";
import { Box } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Skeleton from "@material-ui/lab/Skeleton";
import axios from "services/auth/jwt/config";
import { NotificationManager } from "react-notifications";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import EditarEtapa from "./EditarEtapa";
import SweetAlert from "react-bootstrap-sweetalert";
import { Button } from "@material-ui/core";
import { AiFillPlayCircle } from "react-icons/ai";

import "beautiful-react-diagrams/styles.css";

const useStyles = makeStyles(theme => ({
  fab: {
    position: "fixed",
    zIndex: "1000 !important",
    bottom: theme.spacing(8),
    right: theme.spacing(10)
  },
  fab2: {
    position: "fixed",
    zIndex: "1000 !important",
    bottom: theme.spacing(22),
    right: theme.spacing(8)
  }
}));

const CustomNode = props => {
  const { content, data } = props;

  return (
    <div
      style={{
        background: "#d6dbdf",
        borderRadius: "10px",
        borderColor: "#707b7c",
        borderStyle: "solid",
        borderWidth: "3px",
        height: "70px",
        width: "130px"
      }}
    >
      <div
        style={{
          paddingTop: "10px",
          paddingLeft: "10px",
          paddingRight: "10px",
          color: "#626466",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden"
        }}
      >
        <h3
          style={{
            textAlign: "center"
          }}
        >
          {content}
        </h3>
      </div>
      {data.etapa.tipo_etapa.type === "inicio" ? (
        <div style={{ marginLeft: "50px" }}>
          <AiFillPlayCircle size="30px" color="grey" />
          <Tooltip title="Editar" style={{ marginBottom : '20px'}}>
            <IconButton
              aria-label="delete"
              onClick={() => data.editarEtapa(data.etapa)}
            >
              <EditIcon color="white" />
            </IconButton>
          </Tooltip>
        </div>
      ) : (
        <div style={{ paddingLeft: "20px" }}>
          <Tooltip title="Excluir">
            <IconButton
              aria-label="delete"
              onClick={() => {
                data.deletarEtapa(data.etapa);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          {data.etapa.tipo_etapa.type !== "fim" && (
            <Tooltip title="Editar">
              <IconButton
                aria-label="delete"
                onClick={() => data.editarEtapa(data.etapa)}
              >
                <EditIcon color="white" />
              </IconButton>
            </Tooltip>
          )}
        </div>
      )}
    </div>
  );
};

// the diagram model
const initialSchema = createSchema({});

const UncontrolledDiagram = ({ bot, handleDialog }) => {
  const classes = useStyles();

  // create diagrams schema
  const token = localStorage.getItem("token");
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  const [loader, setLoader] = useState(true);
  const [schema, { onChange }] = useSchema(initialSchema);
  const [update, setupdate] = useState(false);

  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [currentEtapa, setCurrentEtapa] = useState();
  const [userSummary, setUserSummary] = useState([]);
  const [openDialogEtapas, setDialogEtapas] = useState(false);
  const [currentBot, setCurrentBot] = useState();
  const [etapas, setEtapas] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const addEtapa = () => {
    setCurrentEtapa(null);
    setOpenEditDialog(true);
  };

  const editarEtapa = data => {
    setCurrentEtapa(data);
    setOpenEditDialog(true);
  };

  const onCancelDelete = () => {
    setCurrentEtapa(null);
    setOpenDeleteDialog(false);
  };

  const handleDeleteEtapa = () => {
    setLoader(true);
    setOpenDeleteDialog(false);
    axios
      .delete(`bot/etapa/${currentEtapa._id}`)
      .then(succes => {
        setupdate(Math.random());
        NotificationManager.success("Etapa deletada com sucesso!");
      })
      .catch(err => {
        setupdate(Math.random());
        NotificationManager.success("Erro ao deletar Etapa!");
      });
  };

  const deletarEtapa = data => {
    setCurrentEtapa(data);
    setOpenDeleteDialog(true);
  };

  const onCloseComposeDialog = () => {
    setCurrentEtapa(null);
    setOpenEditDialog(false);
  };

  // verifica se existe etapas que nao são alcancadas pelas first_etapas
  const getEtapasOrfaos = (etapas, first_etapas) => {
    var etapas_alcancadas = [...first_etapas];
    var next_etapas = [...first_etapas];

    while (next_etapas.length > 0) {
      var etapa = next_etapas[0];
      next_etapas.shift();

      var id_etapa = etapas.findIndex(row => row._id == etapa);

      if (id_etapa != -1) {
        var full_etapa = etapas[id_etapa];

        if (full_etapa.next_etapa) {
          if (!etapas_alcancadas.includes(full_etapa.next_etapa)) {
            next_etapas.push(full_etapa.next_etapa);
            etapas_alcancadas.push(full_etapa.next_etapa);
          }
        } else if (full_etapa.options) {
          for (let index = 0; index < full_etapa.options.length; index++) {
            const element = full_etapa.options[index];
            if (!etapas_alcancadas.includes(element.etapa)) {
              etapas_alcancadas.push(element.etapa);
              next_etapas.push(element.etapa);
            }
          }
        }
      }
    }

    var etapas_orfaos = etapas.map(row => {
      if (!etapas_alcancadas.includes(row._id)) {
        return row._id;
      }
    });

    etapas_orfaos = etapas_orfaos.filter(row => row);

    return etapas_orfaos;
  };

  useEffect(
    () => {
      axios
        .get(`bot/etapa/${bot._id}`)
        .then(success => {
          setEtapas(success.data);

          var ids_etapas = success.data.map(etapa => etapa._id);

          var ids_next_etapa = [];

          success.data.map(etapa => {
            if (etapa.next_etapa) {
              ids_next_etapa.push(etapa.next_etapa);
            }
          });

          var ids_etapas_option = [];

          success.data.map(etapa =>
            etapa.options.map(option => ids_etapas_option.push(option.etapa))
          );

          console.log("options next etapa: ", ids_etapas_option);

          ids_next_etapa = ids_next_etapa.concat(ids_etapas_option);

          console.log("next etapas: ", ids_next_etapa);

          var first_etapa = ids_etapas.filter(x => !ids_next_etapa.includes(x));
          //first_etapa.push("6215b0f416f3568c766c50e3", "6215b11416f356a3306c50e5", "6215b8db16f35613a06c50e7")

          console.log("Primeiras etapas: ", first_etapa);

          first_etapa = [
            ...first_etapa,
            ...getEtapasOrfaos(success.data, first_etapa)
          ];

          // montando schema
          var nodes = [];
          var links = [];

          var next_etapa = first_etapa;
          var x = 10,
            y = 10;

          while (next_etapa.length > 0) {
            var etapas = success.data.filter(etapa =>
              next_etapa.includes(etapa._id)
            );

            etapas.map(etapa => {
              nodes.push({
                id: etapa._id,
                content: etapa.nome_etapa,
                coordinates: [x, y],
                data: { editarEtapa, deletarEtapa, etapa: etapa },
                render: CustomNode
              });
              x = x + 150;
            });

            x = 10;
            y = y + 100;

            // busca next etapa
            // elimina as next etapas que já foram adicionadas.
            var ids_next_etapa = [];

            etapas.map(etapa => {
              if (etapa.next_etapa) {
                // verifica se next_etapa já existe em nodes
                var if_exist_next_etapa_in_node = nodes.filter(
                  row => row.id == etapa.next_etapa
                );

                //( só adiciona em next_etapas se não existir em nodes.
                if (if_exist_next_etapa_in_node.length === 0) {
                  ids_next_etapa.push(etapa.next_etapa);
                }
              }
            });

            var ids_etapas_option = [];

            etapas.map(etapa =>
              etapa.options.map(option => {
                if (option.etapa) {
                  var if_exist_next_etapa_in_node = nodes.filter(
                    row => row.id == option.etapa
                  );

                  if (if_exist_next_etapa_in_node.length === 0) {
                    ids_etapas_option.push(option.etapa);
                  }
                }
              })
            );

            next_etapa = ids_next_etapa.concat(ids_etapas_option);
          }

          // montando links
          // { input: 'node-1', output: 'node-2', readonly: true }
          success.data.map(etapa => {
            // verificar se a etapa que estou apontando também aponta para a etapa.
            // evitar caminho cruzado
            if (etapa.next_etapa) {
              links.push({
                input: etapa._id,
                output: etapa.next_etapa,
                readonly: true
              });
            }

            etapa.options.map(option => {
              if (option.etapa) {
                links.push({
                  input: etapa._id,
                  output: option.etapa,
                  readonly: true
                });
              }
            });
          });

          onChange({ nodes, links });

          setLoader(false);
        })
        .catch(err => {
          NotificationManager.error("Erro ao buscar etapas!");
        });
    },
    [update]
  );

  return (
    <React.Fragment>
      {loader ? (
        <Skeleton variant="rect" height={400} />
      ) : (
        <React.Fragment>
          <div id="diagrama" style={{ height: "30000000px", width: '30000000px' }}>
            <Diagram
              schema={schema}
              onChange={onChange}
              css="overflow: 'scroll';"
            />
          </div>
          <Tooltip title="Adicionar Etapa" aria-label="add" placement="left">
            <Fab
              className={classes.fab2}
              color="secondary"
              aria-label="add"
              onClick={addEtapa}
            >
              <AddIcon />
            </Fab>
          </Tooltip>
          <Tooltip title="Sair" aria-label="add" placement="left">
            <Fab
              className={classes.fab}
              aria-label="exit"
              size="small"
              style={{ backgroundColor: "#de5646", color: "whitesmoke" }}
              onClick={handleDialog}
            >
              <CloseIcon />
            </Fab>
          </Tooltip>
        </React.Fragment>
      )}

      {openEditDialog && (
        <EditarEtapa
          userSummary={userSummary}
          setUserSummary={setUserSummary}
          open={openEditDialog}
          currentEtapa={currentEtapa}
          bot={bot}
          allEtapas={
            currentEtapa ? (
              etapas.filter(
                row =>
                  row._id !== currentEtapa._id &&
                  row.tipo_etapa.type !== "inicio"
              )
            ) : (
              etapas.filter(row => row.tipo_etapa.type !== "inicio")
            )
          }
          handleDialog={onCloseComposeDialog}
          setLoaderSkeleton={setLoader}
          setUpdate={setupdate}
        />
      )}
      <SweetAlert
        show={openDeleteDialog}
        style={{ zIndex: "1", position: "fixed" }}
        warning
        customButtons={
          <React.Fragment>
            <Button
              color="default"
              style={{ margin: "10px" }}
              onClick={onCancelDelete}
            >
              Cancelar
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleDeleteEtapa}
            >
              Deletar
            </Button>
          </React.Fragment>
        }
        showCancel
        showConfirm
        title="Deletar"
      >{`Deseja realmente deletar essa etapa!`}</SweetAlert>
    </React.Fragment>
  );
};

export default UncontrolledDiagram;
