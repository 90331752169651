import React, {useState, useEffect} from "react";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import GridContainer from "@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import AppTextInput from "@jumbo/components/Common/formElements/AppTextInput";
import CmtAvatar from "@coremat/CmtAvatar";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import {useDropzone} from "react-dropzone";
import Button from "@material-ui/core/Button";
import CmtList from "@coremat/CmtList";
import IconButton from "@material-ui/core/IconButton";
import AppSelectBox from "@jumbo/components/Common/formElements/AppSelectBox";
import {emailNotValid, requiredMessage} from "@jumbo/constants/ErrorMessages";
import {
  updateContactsLabel,
  createContact,
  onUpdateContact,
  getContactsList,
  deleteContact
} from "redux/actions/ContactApp";
import {useDispatch, useSelector} from "react-redux";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import CancelIcon from "@material-ui/icons/Cancel";
import {isValidEmail} from "@jumbo/utils/commonHelper";
import {v4 as uuidv4} from "uuid";
import UploadDialog from "../../../Files/UplaodFile";
import {URL} from "../../../../../util/socket";
import clsx from "clsx";
import Checkbox from "@material-ui/core/Checkbox";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import Tooltip from "@material-ui/core/Tooltip";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DeleteIcon from "@material-ui/icons/Delete";
import DoneIcon from "@material-ui/icons/Done";
import LabelIcon from "@material-ui/icons/Label";

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: "relative"
  },
  dialogTitleRoot: {
    "& .MuiTypography-h6": {
      fontSize: 18,
      color: theme.palette.common.dark
    }
  },
  actionOptionRoot: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    transition: "all 0.3s ease",
    opacity: 0,
    visibility: "hidden",
    transform: "translateX(100%)"
  },
  titleLabelsRoot: {
    fontSize: 10,
    letterSpacing: 1.5,
    color: theme.palette.text.secondary,
    textTransform: "uppercase",
    padding: "16px 16px 8px"
  },
  starViewRoot: {
    transition: "all 0.3s ease",
    transform: "translateX(200%)"
  }
}));

function NumberFormatCustom(props){
  const {onChange, ...other} = props;

  return (
    <NumberFormat
      {...other}
      onValueChange={values => {
        onChange(values.formattedValue);
      }}
      format="+## (##) #####-####"
    />
  );
}

const labels = [ {title: "Celular", slug: "celular"} ];

const splitName = contact => {
  if (contact) {
    const [ fName, mName, lName ] = contact.name.split(" ");
    return [ fName, lName ? mName + " " + lName : mName ];
  }

  return [ "", "" ];
};

const CreateContact = ({open, handleDialog}) => {
  const {currentContact, labelsList, filterType} = useSelector(
    ({contactApp}) => contactApp
  );
  const dispatch = useDispatch();
  const classes = useStyles();
  const [ fName, lName ] = splitName(currentContact);
  const [ profile_pic, setProfile_pic ] = useState(
    currentContact ? currentContact.profile_pic : ""
  );
  const [ fname, setFname ] = useState(fName);
  const [ lname, setLname ] = useState(lName);
  const [ email, setEmail ] = useState(
    currentContact ? currentContact.email : ""
  );
  const [ company, setCompany ] = useState(
    currentContact ? currentContact.company : ""
  );
  const [ designation, setDesignation ] = useState(
    currentContact ? currentContact.designation : ""
  );
  const [ instagram, setInstagram ] = useState(
    currentContact ? currentContact.instagram : ""
  );
  const [ facebook, setFacebook ] = useState(
    currentContact ? currentContact.facebook : ""
  );
  const [ phones, setPhones ] = useState(
    currentContact ? currentContact.phones : [ {phone: "", label: "celular"} ]
  );
  const [ starred, setStarred ] = useState(
    currentContact ? currentContact.starred : false
  );
  const [ labels, setLabels ] = useState(
    currentContact ? currentContact.labels : []
  );

  const [ showMoreOptions, setShowMoreOptions ] = useState(null);

  const [ dialogUpload, closeDialogUpload ] = useState(false);

  const [ fnameError, setFnameError ] = useState("");
  const [ emailError, setEmailError ] = useState("");
  const [ phoneError, setPhoneError ] = useState("");

  const onHideMoreOptions = () => {
    setShowMoreOptions(null);
  };

  const onClickDeleteOption = () => {
    dispatch(deleteContact([ currentContact.id ]));
    dispatch(getContactsList(filterType));
    onHideMoreOptions();
    handleDialog();
  };

  const onClickLabelOption = label => {
    dispatch(updateContactsLabel([ currentContact.id ], label));
    onHideMoreOptions();
  };

  const onRemovePhoneRow = index => {
    const updatedList = [ ...phones ];
    updatedList.splice(index, 1);
    setPhones(updatedList);
  };
  const onAddPhoneNo = (number, index) => {
    const updatedList = [ ...phones ];
    updatedList[index].phone = number;
    setPhones(updatedList);
    setPhoneError("");
  };

  const onSelectLabel = (value, index) => {
    const updatedList = [ ...phones ];
    updatedList[index].label = value;
    setPhones(updatedList);
  };

  const isPhonesMultiple = phones.length > 1;

  const checkValidations = () => {
    const phoneNumbers = phones.filter(item => item.phone.trim());
    if (!fname) {
      setFnameError(requiredMessage);
    } else if (email && !isValidEmail(email)) {
      setEmailError(emailNotValid);
    } else if (phoneNumbers.length === 0) {
      setPhoneError(requiredMessage);
    } else {
      handleSubmit(phoneNumbers);
    }
  };

  const handleSubmit = phoneNumbers => {
    if (currentContact) {
      const contact = {
        profile_pic,
        name: `${fname} ${lname}`,
        email,
        folder: "contacts",
        phones: phoneNumbers.map(row => {
          return {
            label: row.label,
            phone: row.phone.replace(/[^a-zA-Z0-9]/g, "").replace(/\s/g, "")
          };
        }),
        company,
        starred,
        designation,
        facebook,
        instagram
      };
      dispatch(onUpdateContact({...currentContact, ...contact}));
    } else {
      const contact = {
        id: uuidv4(),
        profile_pic,
        name: `${fname} ${lname || ""}`,
        email,
        starred,
        folder: "contacts",
        phones: phoneNumbers.map(row => {
          return {
            label: row.label,
            phone: row.phone.replace(/[^a-zA-Z0-9 ]/g, "").replace(/\s/g, "")
          };
        }),
        company,
        designation,
        facebook,
        instagram
      };
      dispatch(createContact(contact));
    }
    handleDialog();
  };

  const selectedImage = file => {
    setProfile_pic(`${URL}/${file.link}`);
    closeDialogUpload(false);
  };

  const onShowMoreOptions = event => {
    setShowMoreOptions(event.currentTarget);
  };

  return (
    <Dialog open={open} onClose={handleDialog} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>
        {currentContact && (
          <React.Fragment>
            <Tooltip title="+ opções">
              <IconButton size="small" onClick={onShowMoreOptions}>
                <MoreVertIcon />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={showMoreOptions}
              open={Boolean(showMoreOptions)}
              onClose={onHideMoreOptions}
            >
              <MenuItem
                onClick={onClickDeleteOption}
                className={classes.menuItemsRoot}
              >
                <DeleteIcon />
                <Box component="span" ml={4}>
                  Deletar
                </Box>
              </MenuItem>
              <Box className={classes.titleLabelsRoot}>Grupos</Box>
              <CmtList
                data={labelsList}
                renderRow={(item, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => onClickLabelOption(item.id)}
                    className={classes.menuItemsRoot}
                  >
                    <Box display="flex" alignItems="center" width={1}>
                      <Box>
                        <LabelIcon
                          className={classes.iconBlock}
                          style={{color: item.color}}
                        />
                      </Box>
                      <Box ml={4} component="span">
                        {item.name}
                      </Box>
                      {labels.includes(item.id) && (
                        <Box ml="auto">
                          <DoneIcon className={classes.iconBlock} />
                        </Box>
                      )}
                    </Box>
                  </MenuItem>
                )}
              />
            </Menu>
          </React.Fragment>
        )}
        {currentContact ? "Editar contato" : "Criar novo contato"}
        <Checkbox
          icon={<StarBorderIcon />}
          checkedIcon={<StarIcon style={{color: "#FF8C00"}} />}
          checked={starred}
          onChange={e => setStarred(e.target.checked)}
          size="small"
        />
      </DialogTitle>
      <DialogContent dividers>
        <Box
          display="flex"
          flexDirection={{xs: "column", md: "row"}}
          alignItems="center"
          mb={{xs: 6, md: 5}}
        >
          <Box
            mr={{xs: 0, md: 5}}
            mb={{xs: 3, md: 0}}
            className="pointer"
            onClick={() => closeDialogUpload(true)}
          >
            <CmtAvatar size={70} src={profile_pic} />
          </Box>
          <GridContainer>
            <Grid item xs={12} sm={6}>
              <AppTextInput
                fullWidth
                variant="outlined"
                label="Primeiro nome"
                value={fname}
                onChange={e => {
                  setFname(e.target.value);
                  setFnameError("");
                }}
                helperText={fnameError}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AppTextInput
                fullWidth
                variant="outlined"
                label="Último nome"
                value={lname}
                onChange={e => setLname(e.target.value)}
              />
            </Grid>
          </GridContainer>
        </Box>
        <Box mb={{xs: 6, md: 5}}>
          <AppTextInput
            fullWidth
            variant="outlined"
            label="Email"
            value={email}
            onChange={e => {
              setEmail(e.target.value);
              setEmailError("");
            }}
            helperText={emailError}
          />
        </Box>
        <CmtList
          data={phones}
          renderRow={(item, index) => (
            <GridContainer style={{marginBottom: 12}} key={index}>
              <Grid item xs={12} sm={isPhonesMultiple ? 6 : 12}>
                <AppTextInput
                  fullWidth
                  variant="outlined"
                  label="Telefone"
                  focused={true}
                  value={item.phone}
                  onChange={number => onAddPhoneNo(number, index)}
                  helperText={phoneError}
                  InputProps={{
                    inputComponent: NumberFormatCustom
                  }}
                />
              </Grid>
              {/* <Grid item xs={isPhonesMultiple ? 9 : 12} sm={4}>
                <AppSelectBox
                  fullWidth
                  data={["celular"]}
                  label="Tipo"
                  valueKey="slug"
                  variant="outlined"
                  labelKey="title"
                  value={item.label}
                  onChange={e => onSelectLabel(e.target.value, index)}
                />
              </Grid>
                */}
              {isPhonesMultiple && (
                <Grid item xs={3} sm={2}>
                  <IconButton onClick={() => onRemovePhoneRow(index)}>
                    <CancelIcon />
                  </IconButton>
                </Grid>
              )}
            </GridContainer>
          )}
        />
        {/*<Box
          mb={{ xs: 6, md: 5 }}
          display="flex"
          alignItems="center"
          onClick={onAddPhoneRow}
          className="pointer"
          color="primary.main">
          <AddCircleOutlineIcon />
          <Box ml={2}>Add More</Box>
        </Box>*/}
        <GridContainer style={{marginBottom: 12}}>
          <Grid item xs={12} sm={6}>
            <AppTextInput
              fullWidth
              variant="outlined"
              label="Empresa"
              value={company}
              onChange={e => setCompany(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AppTextInput
              fullWidth
              variant="outlined"
              label="Profissão"
              value={designation}
              onChange={e => setDesignation(e.target.value)}
            />
          </Grid>
        </GridContainer>
        <GridContainer style={{marginBottom: 12}}>
          <Grid item xs={12} sm={6}>
            <AppTextInput
              fullWidth
              variant="outlined"
              label="Instagram"
              value={instagram}
              onChange={e => setInstagram(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AppTextInput
              fullWidth
              variant="outlined"
              label="Facebook"
              value={facebook}
              onChange={e => setFacebook(e.target.value)}
            />
          </Grid>
        </GridContainer>
        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button onClick={handleDialog}>Cancelar</Button>
          <Box ml={2}>
            <Button
              variant="contained"
              color="secondary"
              onClick={checkValidations}
            >
              Salvar
            </Button>
          </Box>
        </Box>
        <UploadDialog
          selectedImage={selectedImage}
          dialogUpload={dialogUpload}
          closeDialogUpload={closeDialogUpload}
        />
      </DialogContent>
    </Dialog>
  );
};

export default CreateContact;

CreateContact.prototype = {
  open: PropTypes.bool.isRequired,
  handleDialog: PropTypes.func,
  selectedContact: PropTypes.object
};

CreateContact.defaultProps = {
  selectedContact: null
};
