const INIT_STATE = {
  log: {}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "NEW_LOG": {
      return {...state, log: action.payload};
    }
    default:
      return state;
  }
};
