import React, {useState, useEffect} from "react";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import StarIcon from "@material-ui/icons/StarBorder";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import GridContainer from "../../../@jumbo/components/GridContainer";
import axios from "services/auth/jwt/config";
import Switch from "@material-ui/core/Switch";
import {AuhMethods} from "../../../services/auth";
import {CurrentAuthMethod} from "../../../@jumbo/constants/AppConstants";
import {useDispatch, useSelector} from "react-redux";
import PaymentMethod from "./CreditCard";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import config from "../../../config";

const LAYOUT = {
  zapresponder: {
    cnpj: "24.713.709/0001-00",
    site: "https://zapresponder.com.br",
    name: "ZapResponder",
    link:
      "https://cadastroempresa.com.br/cnpj/24.713.709/0001-00-zap-responder-afonso-das-chagas-martins"
  },

  multdesk: {
    name: "Multdesk",
    site: "https://multdesk.com.br",
    cnpj: "41.496.228/0001-00",
    link:
      "https://cadastroempresa.com.br/cnpj/41.496.228/0001-00-mdl-inteligencia-de-negocios-ltda-mdl-desenvolvimento-estrategico-e-inteligencia-de-negocios-ltda"
  }
};

function Copyright(){
  return (
    <React.Fragment>
      <Typography variant="body2" color="textSecondary" align="center">
        <Link
          color="inherit"
          target="_blank"
          href={LAYOUT[config.EMPRESA].link}
        >
          CNPJ: {LAYOUT[config.EMPRESA].cnpj}
        </Link>
      </Typography>
      <Typography variant="body2" color="textSecondary" align="center">
        {"Copyright © "}
        <Link color="inherit" href={LAYOUT[config.EMPRESA].site}>
          {LAYOUT[config.EMPRESA].name}
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    </React.Fragment>
  );
}

const useStyles = makeStyles(theme => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none"
    }
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  toolbar: {
    flexWrap: "wrap"
  },
  toolbarTitle: {
    flexGrow: 1
  },
  link: {
    margin: theme.spacing(1, 1.5)
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6)
  },
  heroContentDuracion: {
    padding: theme.spacing(0, 0, 6)
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[700]
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6)
    }
  }
}));

const MySwal = withReactContent(Swal);

const Pricing = ({setNovoPlano}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [ tiers, setTiers ] = useState([]);
  const [ data, setData ] = useState([]);
  const [ currentPlano, setCurrentPlano ] = useState();
  const [ duration, setDuration ] = useState("Mês");
  const [ loader, setLoader ] = useState(false);
  const {authUser} = useSelector(({auth}) => auth);
  const {plano} = authUser;

  const token = localStorage.getItem("token");
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  useEffect(() => {
    axios
      .get("plano")
      .then(success => {
        var planos = success.data.planos.map(plano => {
          var description = [
            `${plano.qtd_whatsapp} Whatsapp`,
            "Envio de mensagens ilimitados",
            "Email support"
          ];

          if (plano.qtd_bot > 0) {
            description.push(`${plano.qtd_bot} - Robôs`);
          }

          if (plano.qtd_atendentes > 0) {
            description.push(`${plano.qtd_atendentes} - Atendentes`);
          }

          if (plano.nome.includes("Ligth")) {
            // description.push(`Importação de conversas`);
            description.push(`Suporte na instalação`);
          }

          return {
            title: plano.nome,
            price: plano.valor,
            subheader: authUser.plano ? "" : "Teste 7 dias grátis",
            buttonText: "Realizar teste grátis",
            buttonVariant: "contained",
            description,
            intervalo: plano.intervalo,
            full_plan: plano
          };
        });
        setData(planos);
        setTiers(planos.filter(row => row.intervalo == 1));
      })
      .catch(err => console.log(err));
  }, []);

  const handleChangeDuration = () => {
    if (duration === "Mês") {
      setDuration("Trimestral");
      setTiers(data.filter(row => row.intervalo == 3));
    } else {
      setDuration("Mês");
      setTiers(data.filter(row => row.intervalo == 1));
    }
  };

  const onLogoutClick = () => {
    dispatch(AuhMethods[CurrentAuthMethod].onLogout());
  };

  const handleChangePlano = selectedPlan => {
    setLoader(true);
    axios
      .post("plano/ativar", {planoId: selectedPlan.full_plan._id})
      .then(success => {
        setLoader(false);
        MySwal.fire("Sucesso!", "Dados salvos com sucesso!", "success").then(
          succes => (window.location.href = "/app/invoice")
        );
      })
      .catch(err => {
        setLoader(false);
      });
  };

  return (
    <GridContainer>
      <Grid item xs={12}>
        <CssBaseline />
        <AppBar
          position="static"
          color="default"
          elevation={0}
          className={classes.appBar}
        >
          <Toolbar className={classes.toolbar}>
            <Typography
              variant="h6"
              color="inherit"
              noWrap
              className={classes.toolbarTitle}
            >
              Robô de atendimentos
            </Typography>
            {plano ? (
              <Button
                href="#"
                color="primary"
                variant="outlined"
                onClick={() => setNovoPlano(false)}
                className={classes.link}
              >
                Voltar
              </Button>
            ) : (
              <Button
                href="#"
                color="primary"
                variant="outlined"
                onClick={onLogoutClick}
                className={classes.link}
              >
                Sair
              </Button>
            )}
          </Toolbar>
        </AppBar>
        {/* Hero unit */}
        {currentPlano ? (
          <PaymentMethod
            plano={currentPlano}
            setCurrentPlano={setCurrentPlano}
          />
        ) : (
          <React.Fragment>
            <Container
              maxWidth="sm"
              component="main"
              className={classes.heroContent}
            >
              <Typography
                component="h1"
                variant="h2"
                align="center"
                color="textPrimary"
                gutterBottom
              >
                Escolha seu Plano
              </Typography>
              <Typography
                variant="h5"
                align="center"
                color="textSecondary"
                component="p"
              >
                Escolha a melhor opção para você:
              </Typography>
            </Container>
            {/* End hero unit */}
            <Container
              maxWidth="sm"
              component="main"
              className={classes.heroContentDuracion}
            >
              <Typography
                variant="h5"
                align="center"
                color="textSecondary"
                component="p"
              >
                Mensal{" "}
                <Switch
                  checked={duration === "Trimestral"}
                  onChange={handleChangeDuration}
                />{" "}
                Trimestral
              </Typography>
            </Container>
            <Container maxWidth="md" component="main">
              <Grid container spacing={5} alignItems="flex-end">
                {tiers.map(tier => (
                  // Enterprise card is full width at sm breakpoint
                  <Grid
                    item
                    key={tier.title}
                    xs={12}
                    sm={tier.title === "Enterprise" ? 12 : 6}
                    md={4}
                  >
                    <Card>
                      <CardHeader
                        title={tier.title}
                        subheader={tier.subheader}
                        titleTypographyProps={{align: "center"}}
                        subheaderTypographyProps={{align: "center"}}
                        action={
                          tier.title.includes("Pro") ? <StarIcon /> : null
                        }
                        className={classes.cardHeader}
                      />
                      <CardContent>
                        <div className={classes.cardPricing}>
                          <Typography
                            component="h2"
                            variant="h3"
                            color="textPrimary"
                          >
                            {duration === "Ano" ? (
                              `R$ ${(tier.price / 12).toFixed(2)}`
                            ) : (
                              `R$ ${tier.price}`
                            )}
                          </Typography>
                          <Typography variant="h6" color="textSecondary">
                            /{duration}
                          </Typography>
                        </div>
                        <ul>
                          {tier.description.map(line => (
                            <Typography
                              component="li"
                              variant="subtitle1"
                              align="center"
                              key={line}
                            >
                              {line}
                            </Typography>
                          ))}
                        </ul>
                      </CardContent>
                      <CardActions>
                        {plano ? authUser.is_suspend ? (
                          <Button
                            onClick={() => handleChangePlano(tier)}
                            fullWidth
                            variant="outlined"
                            color="primary"
                          >
                            {" "}
                            Reativar Assinatura
                          </Button>
                        ) : (
                          <Button
                            onClick={() => handleChangePlano(tier)}
                            fullWidth
                            variant={tier.buttonVariant}
                            color="primary"
                          >
                            Escolher Plano
                          </Button>
                        ) : (
                          <Button
                            onClick={() => setCurrentPlano(tier)}
                            fullWidth
                            variant={tier.buttonVariant}
                            color="primary"
                          >
                            {tier.buttonText}
                          </Button>
                        )}
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Container>
          </React.Fragment>
        )}
        {/* Footer */}
        <Container maxWidth="md" component="footer" className={classes.footer}>
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={6} sm={3} key={1}>
              <a href="https://www.iugu.com/" target="_blank" rel="noreferrer">
                <img src="/images/iugu.png" width="30%" />
              </a>
            </Grid>
          </Grid>
          <Box mt={5}>
            <Copyright />
          </Box>
        </Container>
        {/* End footer */}
      </Grid>
      <Backdrop className={classes.backdrop} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </GridContainer>
  );
};

export default Pricing;
