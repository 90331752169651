import React, { useState, useEffect } from "react"
import axios from "services/auth/jwt/config";

import IntlMessages from "@jumbo/utils/IntlMessages";
import PageContainer from "@jumbo/components/PageComponents/layouts/PageContainer";
import GridContainer from "@jumbo/components/GridContainer";
import Typography from "@material-ui/core/Typography";

import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";

import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

import { NotificationManager } from "react-notifications";
import Card from './Card'
import SimpleDialog from "./SimpleDialog"
import Sistemas from "./Sistemas"
import Skeleton from '@mui/material/Skeleton';
import Lottie from 'react-lottie';
import animationData from '@lottie/integracao/no_data.json';

const breadcrumbs = [
    { label: "Home", link: "/" },
    { label: "Integrações", isActive: true }
];

const defaultOptions = {
    loop: true,
    autoplay: true,
    speed: 1,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        textAlign: "left",
        "& > * + *": {
            marginTop: theme.spacing(2)
        }
    },
    fab: {
        position: "fixed",
        zIndex: "1000 !important",
        bottom: theme.spacing(8),
        right: theme.spacing(8)
    },
}))

const renderChip = (sistemas_disponiveis) => {
    return sistemas_disponiveis.map(sistema => <Chip variant="outlined" size="small" avatar={<Avatar src={sistema.imgIcon} />} label={sistema.value} color={sistema.selected ? "primary" : "default"} />)
}

const Integracoes = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [openSimpleDialog, setSimpleDialog] = useState(false)
    const [openDialogEdit, setDialogEdit] = useState(false)
    const [label, setLabel] = useState(false)
    const [sistemasDisponiveis, setSistemasDisponiveis] = useState([])
    const [sistemaSelected, setSistemaSelected] = useState()
    const [refresh, setRefresh] = useState()

    const tokenAuth = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + tokenAuth;

    const handleRemove = (id) => {
        setLoading(true)
        let dataCopy = [...data]
        dataCopy = dataCopy.filter(row => row.id != id)
        setData(dataCopy)
        setLoading(false)

        axios.delete(`/integracao/${id}`)
            .then(success => NotificationManager.success("Integração removida!"))
            .catch(err => NotificationManager.error("Erro ao remover integração!"))

    }

    const handleChangeActivated = (integracao) => {

        const dataEdit = {
            _id: integracao.id,
            activated: !integracao.activated
        }

        let dataCopy = [...data]
        let index = dataCopy.findIndex(row => row.id == integracao.id)
        dataCopy[index].activated = !integracao.activated
        setData(dataCopy)

        axios.put("/integracao", dataEdit)
            .then(success => NotificationManager.success("Integração atualizada!"))
            .catch(err => NotificationManager.error("Erro ao atualizar integração"))

    }

    const handleCloseSimpleDialog = (label) => {
        if (label) {
            setLabel(label)
            setDialogEdit(true)
        }
        setSimpleDialog(false)
    }

    const handleCloseEditDialog = () => {
        setLabel(null)
        setDialogEdit(null)
        setSistemaSelected(null)
        setRefresh(Math.random())
    }

    const handleShow = (itemSelected, label) => {
        setLabel(label)
        setSistemaSelected(itemSelected)
        setDialogEdit(true)
    }

    useEffect(() => {
        setLoading(true)
        Promise.all([
            axios
                .get("/integracaoDisponivel")
                .then(success => {
                    const sistemas = success.data.map(row => (
                        {
                            id: row._id,
                            label: row.label,
                            value: row.nome,
                            selected: true,
                            img: row.img,
                            imgIcon: row.imgIcon
                        }
                    ))
                    setSistemasDisponiveis(sistemas)
                    return success
                })
                .catch(err => err),
            axios
                .get("/integracao")
                .then(success => {
                    const integracoes = success.data.map(row => (
                        {
                            id: row._id,
                            imgIcon: row.sistema.imgIcon,
                            img: row.sistema.img,
                            nome: row.nome,
                            token: row.token,
                            activated: row.activated,
                            webhookUrl: row.webhookUrl,
                            title: row.sistema.nome,
                            label: row.sistema.label,
                            subtitle: `#${row._id}`,
                            body: row.descricao
                        }
                    ))
                    setData(integracoes)
                    return success
                })
                .catch(err => err)
        ]).then(success => setLoading(false))

    }, [refresh])


    return (
        <PageContainer
            heading={<IntlMessages id="sidebar.Integracoes" />}
            breadcrumbs={breadcrumbs}
        >
            <GridContainer style={{ marginBottom: '10px' }}>
                <Grid item xs={12} md={12}>
                    <Box className={classes.root} marginTop="2px" marginBottom="2px">
                        <Typography variant="h4" component="div" color="textSecondary">
                            O Multdesk integra-se perfeitamente a uma variedade de sistemas, ampliando as capacidades do seu ambiente e proporcionando uma experiência ainda mais completa. Simplesmente adicione as credenciais dos sistemas disponíveis e desfrute do melhor que nossa plataforma tem a oferecer.
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Box className={classes.root} marginTop="2px" marginBottom="10px">
                        <Typography variant="h6" component="div">
                            Sistemas disponíveis
                        </Typography>
                    </Box>
                    <Stack direction="row" spacing={1}>
                        {loading
                            ? <Skeleton animation="wave" variant="rounded" width={80} height={20} />
                            : renderChip(sistemasDisponiveis)
                        }
                    </Stack>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Box className={classes.root} marginTop="2px" marginBottom="10px">
                        <Typography variant="h3" component="div">
                            Minhas integrações
                        </Typography>
                    </Box>
                </Grid>
            </GridContainer>
            <GridContainer style={{ marginBottom: '10px' }}>
                {loading
                    ? Array.from({ length: 4 }).map(row =>
                        <Grid item xs={3} md={3}>
                            <Card loading={loading} />
                        </Grid>)
                    : data.length == 0
                        ?
                        <Grid item xs={12} md={12} alignContent="center">
                            <Lottie
                                options={defaultOptions}
                                height={350}
                                width={350}
                            />
                            <Box className={classes.root} marginTop="2px" marginBottom="10px">
                                <Typography variant="span" component="div" align="center" color="textSecondary">
                                    Para começar clique "+"
                                </Typography>
                            </Box>
                        </Grid>
                        : data.map(sistema =>
                            <Grid item xs={3} md={3} >
                                <Card
                                    loading={false}
                                    imgIcon={sistema.imgIcon}
                                    img={sistema.img}
                                    body={sistema.body}
                                    title={sistema.title}
                                    subtitle={sistema.subtitle}
                                    id={sistema.id}
                                    label={sistema.label}
                                    data={sistema}
                                    activated={sistema.activated}
                                    handleRemove={handleRemove}
                                    handleShow={handleShow}
                                    handleChangeActivated={handleChangeActivated}
                                />
                            </Grid>)
                }
            </GridContainer>
            <Tooltip title="Adicionar" aria-label="add">
                <Fab
                    className={classes.fab}
                    size="medium"
                    color="secondary"
                    aria-label="edit"
                    onClick={() => setSimpleDialog(true)}
                >
                    <AddIcon />
                </Fab>
            </Tooltip>
            <SimpleDialog open={openSimpleDialog} onClose={handleCloseSimpleDialog} sistemas={sistemasDisponiveis} />
            <Sistemas label={label} open={openDialogEdit} itemSelected={sistemaSelected} onClose={handleCloseEditDialog} />
        </PageContainer>
    )

}



export default Integracoes