import React, {lazy, Suspense} from "react";
import {Route, Switch} from "react-router";
import PageLoader from "@jumbo/components/PageComponents/PageLoader";
import Setor from "./setor";
import Atendente from "./Atendente";
import Contatos from "./Contatos";
import Campanhas from "./Campanhas";
import Configuracoes from "./Configuracoes";
import Avaliacoes from "./Avaliacoes";

const ExtraPages = ({match}) => {
  const requestedUrl = match.url.replace(/\/$/, "");

  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Route path={`${requestedUrl}/setor`} component={Setor} />
        <Route path={`${requestedUrl}/atendente`} component={Atendente} />
        <Route path={`${requestedUrl}/contatos`} component={Contatos} />
        <Route path={`${requestedUrl}/campanhas`} component={Campanhas} />
        <Route path={`${requestedUrl}/avaliacoes`} component={Avaliacoes} />
        <Route
          path={`${requestedUrl}/configuracoes`}
          component={Configuracoes}
        />
        <Route component={lazy(() => import("../404"))} />
      </Switch>
    </Suspense>
  );
};

export default ExtraPages;
