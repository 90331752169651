import React, {useState} from "react";

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import ListCreditCard from "./NewRequests";

const Transition = React.forwardRef(function Transition(props, ref){
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  fab: {
    position: "fixed",
    zIndex: "1000 !important",
    bottom: theme.spacing(8),
    right: theme.spacing(10)
  },
  fab2: {
    position: "fixed",
    zIndex: "1000 !important",
    bottom: theme.spacing(22),
    right: theme.spacing(8)
  }
}));

const EditarCartaoCredito = ({open, closeDialog}) => {
  const classes = useStyles();
  const [ newCardCredit, setNewCardCredit ] = useState(false);

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={closeDialog}
        fullScreen
        TransitionComponent={Transition}
      >
        <DialogTitle>Alterar cartão de crédito</DialogTitle>
        <DialogContent dividers>
          <Grid item xs={12}>
            <ListCreditCard
              newCardCredit={newCardCredit}
              setNewCardCredit={setNewCardCredit}
            />
          </Grid>
        </DialogContent>
        {!newCardCredit && (
          <Tooltip title="Novo cartão" aria-label="add" placement="left">
            <Fab
              className={classes.fab2}
              color="secondary"
              aria-label="add"
              onClick={() => setNewCardCredit(true)}
            >
              <AddIcon />
            </Fab>
          </Tooltip>
        )}
        <Tooltip title="Sair" aria-label="add" placement="left">
          <Fab
            className={classes.fab}
            aria-label="exit"
            size="small"
            style={{backgroundColor: "#de5646", color: "whitesmoke"}}
            onClick={closeDialog}
          >
            <CloseIcon />
          </Fab>
        </Tooltip>
      </Dialog>
    </React.Fragment>
  );
};

export default EditarCartaoCredito;
